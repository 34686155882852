import { useContext } from 'react';
import { Mode } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { TabBodyRowContext } from '.';
import { EMPTY_STRING } from 'constants/general';
import TabBodyRowNotice from './tabBodyRowNotice';
import HabitPackAndLibraryActivitiesAutocompleteInput from 'components/setting-generator/HabitPackAndLibraryActivitiesAutocompleteInput';

const TabBodyRowName = () => {
  const { t } = useTranslation();
  const { mode } = useAppSelector((state) => state.setting);
  const {
    type,
    position,
    currentActivity,
    isActivityFromHabitPack,
    isEditingAllowed
  } = useContext(TabBodyRowContext);

  return (
    <div
      className={`w-full lg:w-2/5 xl:w-[55%] h-fit flex flex-col justify-center  ${
        isActivityFromHabitPack && 'mb-2 pb-4'
      } relative`}
    >
      <h6 className='text-xs pb-0.5 font-medium'>{t('habit')}</h6>
      <HabitPackAndLibraryActivitiesAutocompleteInput
        name={currentActivity?.name ?? EMPTY_STRING}
        position={position}
        type={type}
        isEditingAllowed={isEditingAllowed}
      />
      {!isEditingAllowed && (
        <TabBodyRowNotice
          message={
            mode === Mode.LIBRARY
              ? t('activity_library_edit_mode_description')
              : t('activity_is_from_habit_pack')
          }
        />
      )}
    </div>
  );
};

export default TabBodyRowName;
