import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BTN_FB_SIZE, BTN_FB_VARIANT, Entitlement } from 'constants/enum';
import moment from 'moment';
import {
  MAC_APP_DATA_LOCATION,
  MAC_APP_DATA_MESSAGE,
  POST_HOG_EVENT,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PRICE_ID
} from 'constants/general';
import { getSubscriptionPlanTitle } from 'utils/support';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { t } from 'i18next';
import { updateShowCancelReasonModal } from 'store/reducer/user/slice';
import { getStripeCheckoutURL } from 'services/subscription';
import { subscriptionInfoSelector } from 'store/reducer/user/selectors';
import { useEffect } from 'react';
import { isDarkModeActivated, isRouteEmbedded } from 'utils/validation';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { captureEvents } from 'utils/events';
import { themeSelector } from 'store/reducer/setting/selectors';

const currentSubscriptionProductIdentifiers = [
  SUBSCRIPTION_PLAN.PERSONAL_MONTHLY,
  SUBSCRIPTION_PLAN.TEAM
];

const SubscriptionInfo = () => {
  const dispatch = useAppDispatch();
  const {
    subscriptionInfo,
    availableStripePlans,
    showCancelReasonModal,
    platform,
    userEmail
  } = useAppSelector(subscriptionInfoSelector);

  const subscriptionEntitlements = Object.values(
    subscriptionInfo?.entitlements ?? {}
  );
  const entitlementsWithOutTrials = subscriptionEntitlements?.filter(
    (entitlement) =>
      !entitlement.product_identifier.includes('trial') &&
      !entitlement.product_identifier.includes('promo')
  );
  const entitlements = entitlementsWithOutTrials.length
    ? entitlementsWithOutTrials
    : subscriptionEntitlements;

  useEffect(() => {
    isRouteEmbedded &&
      sendDataToPlatform(
        platform,
        entitlementsWithOutTrials.length
          ? MAC_APP_DATA_MESSAGE.ACTIVE_SUBSCRIPTION
          : MAC_APP_DATA_MESSAGE.INACTIVE_SUBSCRIPTION,
        MAC_APP_DATA_LOCATION.SEND_USER_SUBSCRIPTION_STATUS
      );
  }, [isRouteEmbedded]);

  return (
    <div
      className={`w-full rounded-xl border-4 border-orange-300 ${isDarkModeActivated(useAppSelector(themeSelector)) ? 'bg-gray-600 ' : 'bg-white'} flex flex-col gap-2 sm:gap-4 md:gap-6 mb-8 shadow-md relative`}
    >
      {entitlements?.length
        ? entitlements.map((entitlement) => {
            const subscription_name =
              availableStripePlans.find(
                (plan) => plan.id === entitlement.product_identifier
              )?.name ??
              getSubscriptionPlanTitle(entitlement.product_identifier);

            const hasActiveSubscription = moment(
              entitlement.expires_date
            ).isSameOrAfter(moment(), 'second');
            const original_expiry_date = moment(entitlement.purchase_date).add(
              entitlement.product_identifier ===
                SUBSCRIPTION_PLAN.PERSONAL_MONTHLY
                ? SUBSCRIPTION_PERIOD.PERSONAL_MONTHLY
                : SUBSCRIPTION_PERIOD.PERSONAL_ANNUAL,
              'days'
            );
            const hasUnfinishedPersonalSubscriptionPeriod = [
              SUBSCRIPTION_PLAN.PERSONAL_MONTHLY,
              SUBSCRIPTION_PLAN.PERSONAL_ANNUAL
            ].includes(entitlement.product_identifier)
              ? moment().isSameOrBefore(original_expiry_date, 'day')
              : false;

            return (
              <div
                key={entitlement.purchase_date}
                className='flex flex-col gap-1 border-b border-sunRayFourth px-6 py-4'
              >
                {subscription_name && (
                  <p className='w-fit text-base md:text-lg font-semibold relative'>
                    {subscription_name}
                    {subscriptionInfo.activeEntitlements?.includes(
                      Entitlement.TEAM_OWNER as string
                    ) &&
                      entitlement.product_identifier ===
                        SUBSCRIPTION_PLAN.TEAM && (
                        <span className='absolute -top-3 -right-3 text-xs bg-blue-600 text-white px-1 shadow rounded font-normal capitalize'>
                          {t('admin_team.owner')}
                        </span>
                      )}
                  </p>
                )}
                <div className='flex justify-between text-sm md:text-base'>
                  <p>{t('subscription_status')}</p>
                  <p>
                    {hasActiveSubscription ||
                    hasUnfinishedPersonalSubscriptionPeriod
                      ? t('active')
                      : t('inactive')}
                  </p>
                </div>

                {!hasActiveSubscription &&
                hasUnfinishedPersonalSubscriptionPeriod ? (
                  <p className='text-sm md:text-base text-center italic'>
                    {t(
                      'your_subscription_has_been_canceled_but_will_remain_active',
                      {
                        expiry_date: original_expiry_date.format('MMM DD, YYYY')
                      }
                    )}
                  </p>
                ) : (
                  <>
                    {hasActiveSubscription ? (
                      <div className='flex items-center justify-between gap-2'>
                        <p className='text-sm md:text-base'>
                          {subscriptionInfo?.unsubscribeDetectedAt
                            ? t('expires_on')
                            : t('next_renewal')}
                        </p>
                        <p className='text-sm md:text-base'>
                          {moment(entitlement.expires_date).format(
                            'MMM DD, YYYY'
                          )}
                        </p>
                      </div>
                    ) : null}
                    {currentSubscriptionProductIdentifiers.includes(
                      entitlement.product_identifier
                    ) ? (
                      <ButtonFocusBear
                        onClick={() => {
                          captureEvents(
                            hasActiveSubscription
                              ? POST_HOG_EVENT.CANCEL_SUBSCRIPTION_BUTTON_CLICKED
                              : POST_HOG_EVENT.RENEW_SUBSCRIPTION_BUTTON_CLICKED,
                            userEmail
                          );
                          if (hasActiveSubscription) {
                            dispatch(updateShowCancelReasonModal(true));
                          } else {
                            const price_id =
                              entitlement.product_identifier ===
                              SUBSCRIPTION_PLAN.TEAM
                                ? SUBSCRIPTION_PRICE_ID.TEAM
                                : SUBSCRIPTION_PRICE_ID.PERSONAL_MONTHLY;
                            getStripeCheckoutURL(price_id);
                          }
                        }}
                        title={
                          hasActiveSubscription
                            ? t('cancel_subscription')
                            : t('renew_subscription')
                        }
                        disabled={showCancelReasonModal}
                        size={BTN_FB_SIZE.SMALL}
                        variant={
                          hasActiveSubscription
                            ? BTN_FB_VARIANT.PRIMARY
                            : BTN_FB_VARIANT.SECONDARY
                        }
                        additionalStyles='self-center'
                        disableDarkMode
                      />
                    ) : null}
                  </>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default SubscriptionInfo;
