import CloseCircle from 'assets/icons/CloseCircle';
import Filter from 'assets/icons/Filter';
import COLOR from 'constants/color';
import { ROUTINE, TAB } from 'constants/general';
import _ from 'lodash';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetFilters,
  updateFilterIsSettingToolbarShow
} from 'store/reducer/filter/slice';
import { getNewHabitProps } from 'utils/support';
import AddNewActivityButton from './AddNewActivityButton';

const FilterWidgetActions = () => {
  const dispatch = useAppDispatch();
  const {
    filter: { isSettingToolbarShown },
    setting: { activeTabIndex, tabs, currentSettings, customRoutine }
  } = useAppSelector((state) => state);

  const shouldDisplayAddHabitButton = useMemo(
    () =>
      [TAB.MORNING, TAB.MICRO_BREAKS, TAB.EVENING].includes(
        tabs[activeTabIndex]
      ) || Object.values(ROUTINE).includes(customRoutine),
    [activeTabIndex, customRoutine]
  );

  const [activity_type, sequence_id] = getNewHabitProps(
    tabs,
    activeTabIndex,
    _.cloneDeep(currentSettings),
    customRoutine
  );

  const isRoutineSuggestions = customRoutine === ROUTINE.SUGGESTION;

  return (
    <div className='flex items-center gap-3'>
      {!isRoutineSuggestions && (
        <button
          className={`w-fit h-fit z-10 flex cursor-pointer ${
            isSettingToolbarShown ? '' : 'px-1 py-1.5'
          }`}
          onClick={() => {
            dispatch(updateFilterIsSettingToolbarShow(!isSettingToolbarShown));
            dispatch(resetFilters());
          }}
        >
          {isSettingToolbarShown ? (
            <CloseCircle styles='w-6 h-6' fill={COLOR.BLACK} />
          ) : (
            <Filter />
          )}
        </button>
      )}
      {shouldDisplayAddHabitButton && (
        <AddNewActivityButton
          activity_type={activity_type}
          sequence_id={sequence_id}
        />
      )}
    </div>
  );
};

export default FilterWidgetActions;
