import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import { CHAT_WINDOW_MOBILE_MIN_WIDTH } from 'constants/general';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/reducer/user/extra';
import { getSupportSelector } from 'store/reducer/user/selectors';
import { useWindowSize } from 'usehooks-ts';
import { loadMetaDataFoGetSupport } from 'utils/focusBear_apps';
import { isTrialOrPromoActive } from 'utils/subscriptions';

const GetSupport = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const {
    userId,
    userEmail,
    subscriptionInfo,
    isGuestUser,
    isTokenAddedToInterceptor
  } = useAppSelector(getSupportSelector);

  useEffect(() => {
    loadMetaDataFoGetSupport();
  }, []);

  useEffect(() => {
    isTokenAddedToInterceptor && dispatch(getUserDetails());
  }, [isTokenAddedToInterceptor]);

  const isPaying = useMemo(
    () => isTrialOrPromoActive(subscriptionInfo) || isGuestUser,
    [subscriptionInfo]
  );

  useEffect(() => {
    const sale_iq_script_pre = document.createElement('script');
    sale_iq_script_pre.innerHTML = `window.$zoho=window.$zoho || {};
            $zoho.salesiq=$zoho.salesiq||{ready:function(){}}`;
    sale_iq_script_pre.type = 'text/javascript';
    sale_iq_script_pre.defer = true;
    document.body.append(sale_iq_script_pre);

    const sale_iq_script = document.createElement('script');
    sale_iq_script.src =
      'https://salesiq.zohopublic.com.au/widget?wc=8b0f55dff01a5ffbcd3e74af14a8a7a9a20a66298fb3622b6963b8abf1f00da1';
    sale_iq_script.type = 'text/javascript';
    sale_iq_script.id = 'zsiqscript';
    sale_iq_script.defer = true;
    document.body.append(sale_iq_script);

    const intervalId = setInterval(() => {
      const chatWrapper = document.getElementById('zsiq_chat_wrap');
      const chatBtn = document.getElementById('zsiq_float');
      if (chatBtn && !chatWrapper?.classList.contains('chat-iframe-open')) {
        chatBtn.click();
        chatBtn.style.setProperty('display', 'none');
      }
      if (chatWrapper) {
        chatWrapper.classList.remove('zsiq-medium-size');
        chatWrapper.style.width = '100%';
        chatWrapper.style.setProperty('min-height', '100%', 'important');
        chatWrapper.style.setProperty('height', '100%', 'important');
        chatWrapper.style.right = '0';
        chatWrapper.style.bottom = '0';
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      document.body.removeChild(sale_iq_script);
      document.body.removeChild(sale_iq_script_pre);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isTokenAddedToInterceptor && userId && userEmail) {
      const custom_props_script = document.createElement('script');
      custom_props_script.innerHTML = `
            $zoho.salesiq.visitor.info["email"] = "${userEmail}";
            $zoho.salesiq.visitor.info["id"] = "${userId}";
            $zoho.salesiq.visitor.info["isPaying"] = ${isPaying} ? "Yes" : "No";`;
      custom_props_script.type = 'text/javascript';
      document.body.append(custom_props_script);

      return () => {
        document.body.removeChild(custom_props_script);
      };
    }
  }, [isTokenAddedToInterceptor, userId, userEmail]);

  useEffect(() => {
    if (width < CHAT_WINDOW_MOBILE_MIN_WIDTH) {
      const intervalId = setInterval(() => {
        const iframe = document.getElementById(
          'siq_chatwindow'
        ) as HTMLIFrameElement;

        const chatCloseButton = iframe?.contentWindow?.document?.querySelector(
          '[view=mobile] .chat-win-close'
        ) as HTMLElement;
        if (
          chatCloseButton &&
          window
            .getComputedStyle(chatCloseButton)
            .getPropertyValue('display') !== 'none'
        ) {
          chatCloseButton.style.display = 'none';
        }
      }, 200);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [width]);

  return <InitEmbeddedServices />;
};

export default GetSupport;
