import { createAppSelector } from 'store/hooks';

export const subscriptionSelector = createAppSelector(
  [
    (state) => state.user.isSubscriptionChecking,
    (state) => state.user.isGuestUser,
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.user.isFetchingAvailableStripPlans,
    (state) => state.user.showTeamNameModal,
    (state) => state.setting.themeMode
  ],
  (
    isSubscriptionChecking,
    isGuestUser,
    isTokenAddedToInterceptor,
    isFetchingAvailableStripPlans,
    showTeamNameModal,
    themeMode
  ) => ({
    isSubscriptionChecking,
    isGuestUser,
    isTokenAddedToInterceptor,
    isFetchingAvailableStripPlans,
    showTeamNameModal,
    themeMode
  })
);

export const subPageContentSelector = createAppSelector(
  [
    (state) => state.user.subscriptionInfo,
    (state) => state.user.showCancelReasonModal
  ],
  (subscriptionInfo, showCancelReasonModal) => ({
    subscriptionInfo,
    showCancelReasonModal
  })
);

export const subscriptionInfoSelector = createAppSelector(
  [
    (state) => state.user.subscriptionInfo,
    (state) => state.user.availableStripePlans,
    (state) => state.user.showCancelReasonModal,
    (state) => state.setting.platform,
    (state) => state.user.details?.email
  ],
  (
    subscriptionInfo,
    availableStripePlans,
    showCancelReasonModal,
    platform,
    userEmail
  ) => ({
    subscriptionInfo,
    availableStripePlans,
    showCancelReasonModal,
    platform,
    userEmail
  })
);

export const manageActiveSubscriptionSelector = createAppSelector(
  [
    (state) => state.user.showCancelReasonModal,
    (state) => state.user.subscriptionInfo,
    (state) => state.user.details?.email
  ],
  (showCancelReasonModal, subscriptionInfo, userEmail) => ({
    showCancelReasonModal,
    subscriptionInfo,
    userEmail
  })
);

export const toDoFocusEndSelector = createAppSelector(
  [(state) => state.setting.isTokenAddedToInterceptor],
  (isTokenAddedToInterceptor) => isTokenAddedToInterceptor
);

export const toDoFocusEndTasksSelector = createAppSelector(
  [
    (state) => state.toDo.isUpdatingStatus,
    (state) => state.toDo.isTaskSubmitting,
    (state) => state.setting.platform,
    (state) => state.setting.themeMode
  ],
  (isUpdatingStatus, isTaskSubmitting, platform, themeMode) => ({
    isUpdatingStatus,
    isTaskSubmitting,
    platform,
    themeMode
  })
);

export const toDoFocusEndTaskSelector = createAppSelector(
  [
    (state) => state.toDo.isUpdatingStatus,
    (state) => state.toDo.isTaskSubmitting,
    (state) => state.setting.themeMode
  ],
  (isUpdatingStatus, isTaskSubmitting, themeMode) => ({
    isUpdatingStatus,
    isTaskSubmitting,
    themeMode
  })
);

export const motivationalTonesSelector = createAppSelector(
  [
    (state) => state.setting.aiTone,
    (state) => state.setting.themeMode,
    (state) => state.user.isMotivationalSummaryLoading,
    (state) => state.user.isMotivationalSummaryStopGenerating
  ],
  (
    aiTone,
    themeMode,
    isMotivationalSummaryLoading,
    isMotivationalSummaryStopGenerating
  ) => ({
    aiTone,
    themeMode,
    isMotivationalSummaryLoading,
    isMotivationalSummaryStopGenerating
  })
);

export const motivationalSummarySelector = createAppSelector(
  [
    (state) => state.user.motivationalSummary,
    (state) => state.user.isMotivationalSummaryLoading,
    (state) => state.user.openAIPrompt,
    (state) => state.user.isMotivationalSummaryStopGenerating,
    (state) => state.user?.details,
    (state) => state.general.counter,
    (state) => state.setting.themeMode
  ],
  (
    motivationalSummary,
    isMotivationalSummaryLoading,
    openAIPrompt,
    isMotivationalSummaryStopGenerating,
    userInfo,
    counter,
    themeMode
  ) => ({
    motivationalSummary,
    isMotivationalSummaryLoading,
    openAIPrompt,
    isMotivationalSummaryStopGenerating,
    userInfo,
    counter,
    themeMode
  })
);

export const motivationalPageSelector = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.setting.aiTone,
    (state) => state.setting.language,
    (state) => state.modal.longTermGoalsModal.isLongTermGoalsModalOpened
  ],
  (
    isTokenAddedToInterceptor,
    aiTone,
    language,
    isLongTermGoalsModalOpened
  ) => ({
    isTokenAddedToInterceptor,
    aiTone,
    language,
    isLongTermGoalsModalOpened
  })
);

export const motivationalPageWrapperSelector = createAppSelector(
  [
    (state) => state.setting.themeMode,
    (state) => state.setting.platform,
    (state) => state.setting.font
  ],
  (themeMode, platform, font) => ({
    themeMode,
    platform,
    font
  })
);

export const motivationalSummaryPromptSelector = createAppSelector(
  [
    (state) => state.user.isMotivationalSummaryStopGenerating,
    (state) => state.user.details,
    (state) => state.user.openAIPrompt
  ],
  (isMotivationalSummaryStopGenerating, userInfo, openAIPrompt) => ({
    isMotivationalSummaryStopGenerating,
    userInfo,
    openAIPrompt
  })
);

export const cancelReasonModalSelector = createAppSelector(
  [
    (state) => state.user.isCancelingSubscription,
    (state) => state.user.subscriptionInfo.entitlements,
    (state) => state.user.subscriptionInfo.activeEntitlements?.slice(),
    (state) => state.user.details?.email
  ],
  (isCancelingSubscription, entitlements, activeEntitlements, userEmail) => ({
    isCancelingSubscription,
    entitlements,
    activeEntitlements,
    userEmail
  })
);

export const layoutSelector = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.user.details,
    (state) => state.user.subscriptionInfo,
    (state) => state.user.isGuestUser,
    (state) => state.modal.confirmAction.showConfirmModal
  ],
  (
    isTokenAddedToInterceptor,
    userInfo,
    subscriptionInfo,
    isGuestUser,
    showConfirmModal
  ) => ({
    isTokenAddedToInterceptor,
    userInfo,
    subscriptionInfo,
    isGuestUser,
    showConfirmModal
  })
);

export const getSupportSelector = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.user.details.id,
    (state) => state.user.details.email,
    (state) => state.user.subscriptionInfo,
    (state) => state.user.isGuestUser
  ],
  (
    isTokenAddedToInterceptor,
    userId,
    userEmail,
    subscriptionInfo,
    isGuestUser
  ) => ({
    isTokenAddedToInterceptor,
    userId,
    userEmail,
    subscriptionInfo,
    isGuestUser
  })
);

export const subscriptionPlanSelector = createAppSelector(
  [(state) => state.user.details?.email],
  (userEmail) => userEmail
);
