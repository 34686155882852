export enum HabitPackFormat {
  ROUTINE = 'routine',
  STANDALONE = 'standalone'
}

export enum MarketplaceRequestStatus {
  UNREQUESTED = 'unrequested',
  REQUESTED = 'requested'
}

export enum Entitlement {
  TRIAL = 'trial', // individual access, granted right after signup
  PERSONAL = 'personal', // individual access, purchased by user
  TEAM_MEMBER = 'team_member', // individual access, granted by team owner and valid until the team subscription expired
  TEAM_OWNER = 'team_owner', // team management (ownership) access, purchased by team owner
  TEAM_ADMIN = 'team_admin', // team management (ownership) access, granted by team owner and valid until the team subscription expired
  TEAM_SIZE_5 = 'team_size_5', // purchased team size limit
  TEAM_SIZE_10 = 'team_size_10', // purchased team size limit
  TEAM_SIZE_15 = 'team_size_15' // purchased team size limit
}

export enum Mode {
  DEFAULT = 'default',
  ROUTINE = 'routine',
  STANDALONE = 'standalone',
  FREESTYLE = 'freestyle',
  FOCUS_MODE = 'focus_mode',
  LIBRARY = 'library',
  CUSTOM = 'custom'
}

export enum StatCardTypes {
  ROUTINE = 'routine',
  FOCUS_MODE = 'focus_mode'
}

export enum ActivityTableFilter {
  ALL = 'all',
  MORNING = 'morning',
  EVENING = 'evening',
  BREAKING = 'breaking'
}

export enum OPERATING_SYSTEM {
  WEB = 'Web',
  MAC = 'MacOS',
  WIN = 'Windows',
  ANDROID = 'Android',
  IOS = 'iOS'
}

export enum DaysOfWeek {
  ALL = 'ALL',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
  WEEKEND = 'WEEKEND',
  WEEKDAYS = 'WEEKDAYS'
}

export enum CalendarTypes {
  GOOGLE = 'google',
  YAHOO = 'yahoo',
  OFFICE_365 = 'office_365',
  OUTLOOK = 'outlook',
  ICS = 'ics'
}

export enum CalendarTypeLabels {
  GOOGLE = 'Google',
  YAHOO = 'Yahoo',
  OFFICE_365 = 'Office 365',
  OUTLOOK = 'Outlook',
  ICS = 'ICS File'
}

export enum AppDownloadButtonLabels {
  MAC = 'Mac',
  IOS = 'iOS',
  GOOGLE_PLAY = 'Google Play',
  WINDOWS = 'Windows'
}

export enum LanguageCodes {
  EN = 'en',
  ES = 'es'
}

export enum Languages {
  ENGLISH = 'English',
  SPANISH = 'Spanish'
}

export enum FetchUsersOrderByOptions {
  ALL = '',
  LAST_COMPLETED_SEQUENCE = 'last_completed_sequence_started_at',
  LAST_COMPLETED_FOCUS_MODE = 'last_completed_focus_mode_at',
  MORNING_ROUTINE_STREAK = 'morning_routines_streak',
  EVENING_ROUTINES_STREAK = 'evening_routines_streak',
  FOCUS_MODES_STREAK = 'focus_modes_streak',
  NONE = 'none'
}

export enum ChatBotState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  WAITING = 'WAITING'
}

export enum ChatRoles {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant'
}

export enum AiToneOptions {
  HUMOROUS = 'humorous',
  SASSY = 'sassy',
  COURT_JESTER = 'court jester',
  PIRATE = 'pirate',
  FUTURE_SELF = 'future self in twenty years',
  SCIENTIST = 'scientist',
  CHEERLEADER = 'cheerleader',
  UPBEAT = 'upbeat',
  FACTUAL = 'factual'
}

export enum TO_DO_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum THEME_OPTION {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export enum FONT_OPTION {
  SEGEO_UI = 'Segoe UI',
  SAN_FRANCISCO = 'San Francisco',
  ARIAL = 'Arial',
  COMIC_SANS_MS = 'Comic Sans MS',
  VERDANA = 'Verdana',
  TAHOMA = 'Tahoma',
  TREBUCHET_MS = 'Trebuchet MS',
  HELVETICA = 'Helvetica',
  INTEL_ONE_MONO = 'Intel One Mono',
  OPEN_DYSLEXIC = 'Open Dyslexic',
  CENTURY_GOTHIC = 'Century Gothic',
  ROBOTO = 'Roboto',
  POPPINS = 'Poppins',
  SANS = 'Sans'
}

export enum TEAM_OWNER_ACTION {
  INVITE_MEMBER = 'invite-member',
  REMOVE_MEMBER = 'remove-member',
  ASSIGN_ADMIN = 'assign-admin',
  REMOVE_ADMIN = 'remove-admin',
  UPDATE_NAME = 'update-name',
  CREATE_NAME = 'create-name',
  MASS_UPDATES = 'mass-updates'
}

export enum PAGINATE {
  TO_DO_TASKS = 'to-do-tasks',
  ADMIN_SURVEYS = 'admin-surveys',
  SURVEYS = 'surveys',
  TO_DO_COMPLETED_TASKS = 'to-do-completed-tasks'
}

export enum MOTIVATIONAL_SUMMARY_QUERY_PARAMS {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export enum FLAG {
  SETTINGS_UPDATED = 'settings-updated',
  GEEK_MODE = 'geek-mode',
  SIMPLE_MODE = 'simple-mode',
  DISABLE_EDIT_HABITS = 'disable-edit-habits'
}

export enum INPUT_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  FILE = 'file',
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

export enum LessonCompletionStatus {
  SELF_TAUGHT = 'SELF_TAUGHT',
  TUTORIAL = 'TUTORIAL'
}

export enum SORT_OPTION {
  ASC = 'asc',
  DESC = 'desc'
}

export enum INVALID_FEATURE {
  START_UP = 'start-up',
  SHUTDOWN = 'shutdown',
  BREAK_FREQUENCY = 'break-frequency',
  PACK_NAME = 'pack name',
  PACK_DESCRIPTION_URL = 'pack description url',
  PACK_WELCOME_URL = 'pack welcome url',
  ALLOWED_APPS = 'allowed apps',
  ALLOWED_URLS = 'allowed urls'
}

export enum FEED_BACK_QUESTION {
  BOOLEAN = 'boolean',
  TEXT = 'text',
  NUMBER = 'number',
  TEXT_AND_RATING = 'text-and-rating'
}

export enum CHECKBOX {
  DEFAULT = 'checkbox',
  RADIO = 'radio'
}

export enum SUGGESTED_PACK {
  LIBRARY = 'activity library',
  HABIT_PACK = 'habit pack',
  CREATE_NEW_HABIT = 'create new habit'
}

export enum BTN_FB_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export enum BTN_FB_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}
