import { isUserInternalTest } from './support';
import { CaptureOptions } from 'posthog-js';
import { EventPayload } from 'interfaces';
import postHog from 'services/posthog';
import { store } from 'store';

export const captureEvents = (
  event: string,
  userEmail: string,
  data?: EventPayload,
  options?: CaptureOptions
) => {
  const user_id = store.getState().user.details.id;
  if (!isUserInternalTest(userEmail)) {
    postHogCapture(event, userEmail, data, options);
    sendClarityEvent(event, user_id, data);
  }
};

const postHogCapture = (
  event: string,
  userEmail: string,
  data?: EventPayload,
  options?: CaptureOptions
) => {
  const user_id = store.getState().user.details.id;
  if (!isUserInternalTest(userEmail)) {
    postHog.capture(
      event,
      {
        ...data,
        IDs: user_id,
        distinct_id: user_id
      },
      options
    );
    sendClarityEvent(event, user_id, data);
  }
};

const sendClarityEvent = (
  event: string,
  user_id: string,
  data?: EventPayload
) => {
  if (window.clarity) {
    window.clarity('identify', user_id);
    window.clarity('event', event);
    data &&
      window.clarity(
        'set',
        event,
        JSON.stringify({
          ...data
        })
      );
  } else {
    console.warn('Clarity is not initialized');
  }
};
