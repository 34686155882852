import { Dispatch, SetStateAction } from 'react';
import { WidgetData } from './TeamOwnerActionModal';
import EmailInput from 'components/common/inputs/EmailInput';
import * as EmailValidator from 'email-validator';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getSubscriptionPlanInfo } from 'utils/support';
import { SUBSCRIPTION_PLAN } from 'constants/general';
import { decode } from 'html-entities';
import Tooltip from 'components/common/Tooltip';
import InfoCircle from 'assets/icons/InfoCircle';

interface InputProps {
  title: string;
  value?: string | boolean;
  setValue: (arg: string | boolean) => void;
}

const InputText = ({ title, value, setValue }: InputProps) => {
  return (
    <input
      type='text'
      value={value as string}
      onChange={({ target: { value } }) => setValue?.(value)}
      className='w-full rounded-lg px-4 py-2 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] border border-black text-sm sm:text-base'
      placeholder={title}
    />
  );
};

const InputCheckBox = ({ title, value, setValue }: InputProps) => {
  return (
    <label className='w-fit px-4 py-2 rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] border border-black flex items-start gap-2 sm:gap-3 self-center text-sm sm:text-base text-left'>
      <input
        type='checkbox'
        checked={value as boolean}
        onChange={({ target: { checked } }) => setValue?.(checked)}
        className='outline-none min-w-[14px] sm:min-w-[20px] h-5 cursor-pointer'
      />
      {title}
    </label>
  );
};

const InvitationForm = ({
  widgetData,
  setWidgetData
}: {
  widgetData: WidgetData;
  setWidgetData: Dispatch<SetStateAction<WidgetData>>;
}) => {
  const { t } = useTranslation();
  const { teamMembers } = useAppSelector((state) => state.team);
  const {
    price: { amount, postfix }
  } = getSubscriptionPlanInfo(SUBSCRIPTION_PLAN.PERSONAL_MONTHLY);
  return (
    <div className='w-11/12 sm:w-3/4 flex flex-col items-end gap-6'>
      <EmailInput
        emailValue={widgetData.email}
        onChangeEmail={({ target: { value } }) =>
          setWidgetData((prev) => ({
            ...prev,
            email: value,
            isValidEmail: EmailValidator.validate(value),
            isEmailExists: teamMembers.members.some(
              (member) => member.email === value
            )
          }))
        }
        hasError={!widgetData.isValidEmail || widgetData.isEmailExists}
      />
      <InputText
        title={t('admin_team.first_name')}
        value={widgetData.first_name}
        setValue={(value) =>
          setWidgetData((prev) => ({ ...prev, first_name: value as string }))
        }
      />
      <InputText
        title={t('admin_team.last_name')}
        value={widgetData.last_name}
        setValue={(value) =>
          setWidgetData((prev) => ({ ...prev, last_name: value as string }))
        }
      />
      <div className='w-full flex flex-col items-start'>
        <div className='flex items-center gap-1'>
          <p className='text-sm sm:text-base'>
            {t('admin_team.membership_access_expiry_date')}
          </p>
          <span>
            <Tooltip
              message={t(
                'admin_team.membership_access_expiry_date_description'
              )}
              icon={<InfoCircle />}
              place='right'
            />
          </span>
        </div>
        <input
          type='date'
          value={
            widgetData.member_expiry_date
              ? moment(widgetData.member_expiry_date).format('YYYY-MM-DD')
              : undefined
          }
          onChange={({ target: { value } }) =>
            setWidgetData((prev) => ({
              ...prev,
              member_expiry_date: value
            }))
          }
          className='w-full rounded-lg px-4 py-2 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] border border-black text-sm sm:text-base'
          min={moment().add(1, 'day').format('YYYY-MM-DD')}
        />
      </div>
      <InputCheckBox
        title={t('admin_team.assign_user_as_admin')}
        value={widgetData.is_admin}
        setValue={(value) =>
          setWidgetData((prev) => ({
            ...prev,
            is_admin: value as boolean
          }))
        }
      />
      <InputCheckBox
        title={decode(
          t('admin_team.grant_access_to_focus_bear', {
            price: amount,
            postfix
          })
        )}
        value={widgetData.is_member}
        setValue={(value) =>
          setWidgetData((prev) => ({
            ...prev,
            is_member: value as boolean
          }))
        }
      />
    </div>
  );
};

export default InvitationForm;
