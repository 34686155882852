import PlusCircle from 'assets/icons/PlusCircle';
import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import { t } from 'i18next';
import { useContext, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import { INIT_NEW_TASK, NewTaskModalContext } from '.';
import { addTaskBtnSelector } from 'store/reducer/to-do/selectors';
import COLOR from 'constants/color';
import { POST_HOG_EVENT } from 'constants/general';
import { captureEvents } from 'utils/events';
import { updatePlayerShowNewTaskModal } from 'store/reducer/to-do/slice';

const AddTaskBtn = () => {
  const dispatch = useAppDispatch();
  const { setNewTaskModal } = useContext(NewTaskModalContext);
  const { themeMode, tasks, userId, userEmail } =
    useAppSelector(addTaskBtnSelector);
  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  return (
    <button
      onClick={() => {
        captureEvents(
          POST_HOG_EVENT.TODO_PLAYER_ADD_TASK_BUTTON_CLICKED,
          userEmail,
          { userId }
        );
        setNewTaskModal?.({ ...INIT_NEW_TASK });
        dispatch(updatePlayerShowNewTaskModal(true));
      }}
      className={classNames(
        'min-w-max flex items-center gap-2 px-4 py-2 shadow-md rounded-md my-5 text-sm md:text-base',
        {
          'bg-gray-600': shouldActivateDarkMode,
          'bg-gray-100 hover:bg-orange-200': !shouldActivateDarkMode,
          'my-10 animate-pulse': !tasks.length
        },
        'add_specific_tasks'
      )}
    >
      <Tooltip
        message={t('to_do_player.create_new_task')}
        icon={<PlusCircle styles='w-4 md:w-5 h-auto' fill={COLOR.BLACK} />}
        place='top'
      />
      {t('to_do_player.add_task_to_playlist')}
    </button>
  );
};

export default AddTaskBtn;
