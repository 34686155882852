import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LocalDeviceSetting,
  MotivationalSummaryPayload,
  StripePlanType,
  UserFeedbackPayload
} from 'interfaces';
import { ChatBotState, ChatRoles } from 'constants/enum';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import * as Sentry from '@sentry/react';
import {
  updateError,
  updateIsAccountDeleted,
  updateIsAccountDeleting,
  updateIsLeaderBoardFetching,
  updateIsRequestDataConfirmationModalShown,
  updateIsRequestDataProcessing,
  updateShowPrivacyPolicyModal
} from '../setting/slice';
import { getSubscriptionInfo } from 'services/subscription';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { RootState } from 'store';
import {
  resetMotivationalSummary,
  setStripAvailablePlans,
  setSubscriptionInfo,
  toggleAreOnboardingStatsLoading,
  toggleAreUserDetailsLoading,
  updateIsCurrentActivityPropsUpdating,
  updateIsDailySummaryFetching,
  updateIsFetchingAvailableStripPlans,
  updateIsGuestUser,
  updateIsLocalDeviceSettingUpdating,
  updateIsLogTermGoalUpdating,
  updateIsMotivationalSummaryLoading,
  updateIsMotivationalSummaryStopGenerating,
  updateIsSavingUserFeedback,
  updateIsSubscriptionChecking,
  updateIsUpdatingUserPrivacyPolicy,
  updateIsUpdatingUserProfileDescription,
  updateIsUpdatingUserProfileImage,
  updateIsUsernameUpdating,
  updateIsWeeklyFocusBlockSummaryUpdating,
  updateIsWeeklyStatsUpdating,
  updateMotivationalSummary,
  updateOpenAIPrompt,
  updateShowThankYouModal,
  updateUserMetadata,
  updateUsername
} from './slice';
import { AxiosError } from 'axios';
import { updateIsFocusTemplateInstalling } from '../focus-mode/slice';
import {
  AI_EVENT,
  CONSENT_OPTION,
  EMPTY_STRING,
  HTTP_STATS_CODE,
  LOCAL_STORAGE,
  NUMBER_OF_DAYS,
  PAGINATE_ITEMS,
  QUESTION_MARK,
  TOAST_AUTO_CLOSE_TIMEOUT,
  OPEN_AI_RESPONSE_DATA
} from 'constants/general';
import { getUserInstalledFocusTemplates } from '../focus-mode/extra';
import { updateInstallingPack } from '../habit-pack/slice';
import { getUserInstalledHabitPacks } from '../habit-pack/extra';
import {
  EventStreamContentType,
  fetchEventSource
} from '@microsoft/fetch-event-source';
import {
  getLocalStorage,
  initWeeklySummaryStats,
  updateWeeklySummaryStats
} from 'utils/support';
import { BASE_URL, ROUTES } from 'constants/routes';
import {
  updateAiGeneratedContent,
  updateChatBotState,
  updateChatHistory,
  updateIsChatBotGenerating
} from '../chat-bot/slice';
import { DEFAULT_CONSENT_PAYLOAD } from 'assets/data';
import {
  updateIsAccessRequestGranted,
  updateIsAccessRequesting,
  updateShowRequestDetailsModal
} from '../admin/slice';
import {
  getActivitiesByUserIdOrStripeIdOrActivityType,
  getUserInfoAndFocusModes
} from '../admin/extra';
import { getUserStatsLeaderBoard } from '../setting/extra';
import { updateShouldStopCounter } from '../general/slice';
import { startGeneralCounter } from '../general/extra';
import { DailySummaryStatsType } from 'interfaces/userInterface';
import { isRouteEmbedded } from 'utils/validation';
import { updateIsLongTermGoalsModalOpened } from '../modal/slice';
import { LEADER_BOARD } from 'constants/table';

export const getUserDetails = createAsyncThunk(
  'setting/get_user_details',
  async (_, { dispatch }) => {
    try {
      dispatch(toggleAreUserDetailsLoading(true));
      const { data } = await focusBearApi.get(Endpoints.USER_DETAILS);
      dispatch(toggleAreUserDetailsLoading(false));
      return data;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      toast.warning(i18n.t('errors.couldnt_fetch_user_data'), {
        position: 'bottom-left',
        autoClose: 3000
      });
    }
  }
);

export const getUserSubscriptionInfo = createAsyncThunk(
  'setting/get_user_subscription_info',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsSubscriptionChecking(true));
      const response = await getSubscriptionInfo();
      const isTrialActive = Object.values(response.entitlements ?? {}).every(
        (entitlement) => entitlement.product_identifier.includes('trial')
      );
      dispatch(updateIsGuestUser(isTrialActive));
      dispatch(setSubscriptionInfo(response));
      dispatch(updateIsSubscriptionChecking(false));
    } catch (error) {
      dispatch(
        updateError({
          message: 'errors.couldnt_fetch_your_subscription_info',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getUserAvailablePlans = createAsyncThunk(
  'setting/get_user_availablePlans',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsFetchingAvailableStripPlans(true));
      const { data } = await focusBearApi.get(Endpoints.USER_AVAILABLE_PLANS, {
        headers: {
          'Cache-Control': 'private, max-age=86400, must-revalidate' //@Description: 1 day == 86400 seconds
        }
      });
      const plansWithDefaultPrices = data?.data?.filter(
        (item: StripePlanType) => item.default_price
      );
      dispatch(setStripAvailablePlans(plansWithDefaultPrices));
      dispatch(updateIsFetchingAvailableStripPlans(false));
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(updateIsFetchingAvailableStripPlans(false));
    }
  }
);

export const getUserDetailsCurrentActivityProps = createAsyncThunk(
  'setting/get_user_details_current_activity_props',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(updateIsCurrentActivityPropsUpdating(true));
      const {
        user: { currentActivityProps }
      } = getState() as RootState;
      const { data } = await focusBearApi.get(
        Endpoints.USER_DETAILS_CURRENT_ACTIVITY_PROPS
      );
      return data ?? currentActivityProps;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      toast.warning(
        i18n.t('routine_player.couldnt_fetch_user_activity_props'),
        {
          position: 'bottom-left',
          autoClose: 3000
        }
      );
    }
  }
);

export const getMorningActivityStats = createAsyncThunk(
  'setting/get_morning_activity_stats',
  async (sequence_id: string) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.COMPLETED_ACTIVITY_SEQUENCE_STATS.replace(
          '{activity_sequence_id}',
          sequence_id
        )
      );
      return data ?? {};
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getEveningActivityStats = createAsyncThunk(
  'setting/get_evening_activity_stats',
  async (sequence_id: string) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.COMPLETED_ACTIVITY_SEQUENCE_STATS.replace(
          '{activity_sequence_id}',
          sequence_id
        )
      );
      return data ?? {};
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserSignedUpViaFocusTemplate = createAsyncThunk(
  'user/update_signed_up_via_focus_template',
  async (template_id: string, { dispatch }) => {
    try {
      const { status } = await focusBearApi.put(
        Endpoints.USER_SIGN_UP_TEMPLATE,
        {
          focus_mode_template_id: template_id
        }
      );
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(
          i18n.t('focus_mode.focus_template_installed_successfully')
        );
        dispatch(getUserInstalledFocusTemplates());
        dispatch(getUserDetails());
      } else {
        toast.warning(i18n.t('focus_mode.could_not_save_focus_mode'));
      }
      dispatch(updateIsFocusTemplateInstalling(false));
    } catch (error) {
      toast.warning(i18n.t('focus_mode.could_not_save_focus_mode'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserSignedUpViaHabitPack = createAsyncThunk(
  'user/update_signed_up_via_habit_pack',
  async (pack_id: string, { dispatch }) => {
    try {
      dispatch(updateInstallingPack(true));
      const { status } = await focusBearApi.put(
        Endpoints.USER_SIGN_UP_TEMPLATE,
        { pack_id }
      );
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(i18n.t('market.habit_pack_install_success'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
        dispatch(getUserInstalledHabitPacks(true));
      } else {
        toast.warning(i18n.t('market.habit_pack_install_fail'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
      }
      dispatch(updateInstallingPack(false));
    } catch (error) {
      toast.warning(i18n.t('market.habit_pack_install_fail'), {
        position: 'bottom-left',
        autoClose: TOAST_AUTO_CLOSE_TIMEOUT
      });
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserMetadataProfilePicture = createAsyncThunk(
  'user/update_user_metadata_profile_picture',
  async (
    profile_image: { url: string; file_path?: string },
    { dispatch, getState }
  ) => {
    try {
      dispatch(updateIsUpdatingUserProfileImage(true));
      const { details } = (getState() as RootState).user;
      const { status } = await focusBearApi.put(Endpoints.USER_METADATA, {
        profile_image
      });
      dispatch(updateIsUpdatingUserProfileImage(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(updateUserMetadata({ ...details?.metadata, profile_image }));
        toast.success(i18n.t('profile_updated'));
      } else {
        toast(i18n.t('could_not_update_the_profile_picture'));
      }
    } catch (error) {
      dispatch(updateIsUpdatingUserProfileImage(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserMetadataDescription = createAsyncThunk(
  'user/update_user_metadata_description',
  async (description: string, { dispatch, getState }) => {
    try {
      dispatch(updateIsUpdatingUserProfileDescription(true));
      const { details } = (getState() as RootState).user;
      const { status } = await focusBearApi.put(Endpoints.USER_METADATA, {
        description
      });
      dispatch(updateIsUpdatingUserProfileDescription(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(
          updateUserMetadata({
            ...details?.metadata,
            description: description
          })
        );
        toast.success(i18n.t('profile_updated'));
      } else {
        toast(i18n.t('could_not_update_the_description'));
      }
    } catch (error) {
      dispatch(updateIsUpdatingUserProfileDescription(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const fetchUserLevelStats = createAsyncThunk(
  'user/fetch_level_stats',
  async (_, { dispatch }) => {
    try {
      dispatch(toggleAreOnboardingStatsLoading(true));
      const { data } = await focusBearApi.get(Endpoints.USER_ONBOARDING_STATS);
      return data;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const fetchUserMotivationalSummary = createAsyncThunk(
  'user/fetch_user_motivational_summary',
  async (
    { tone, device_type, language }: MotivationalSummaryPayload,
    { dispatch }
  ) => {
    dispatch(startGeneralCounter());
    dispatch(updateIsMotivationalSummaryLoading(true));
    dispatch(resetMotivationalSummary());

    const handleError = () => {
      dispatch(updateIsMotivationalSummaryStopGenerating(true));
      dispatch(updateIsMotivationalSummaryLoading(false));
      toast.error(i18n.t('errors.couldnt_generate_motivational_message'));
    };

    dispatch(updateIsMotivationalSummaryLoading(true));
    try {
      await fetchEventSource(
        `${BASE_URL}${Endpoints.MOTIVATIONAL_SUMMARY}${QUESTION_MARK}${new URLSearchParams(
          {
            tone,
            device_type,
            language
          }
        ).toString()}`,
        {
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN)}`
          },
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              dispatch(updateIsMotivationalSummaryLoading(false));
              dispatch(updateIsMotivationalSummaryStopGenerating(false));
              dispatch(updateShouldStopCounter(true));
              return;
            } else {
              handleError();
            }
          },
          onmessage({ data, event }) {
            if (event === AI_EVENT.FATAL_ERROR) {
              handleError();
            } else {
              if (
                data &&
                data !== OPEN_AI_RESPONSE_DATA.DONE &&
                !data.startsWith(OPEN_AI_RESPONSE_DATA.PROMPT)
              ) {
                dispatch(updateMotivationalSummary(data));
              } else if (data?.startsWith(OPEN_AI_RESPONSE_DATA.PROMPT)) {
                dispatch(
                  updateOpenAIPrompt(
                    data
                      ?.replace(OPEN_AI_RESPONSE_DATA.PROMPT, EMPTY_STRING)
                      ?.trim()
                  )
                );
              }
            }
          }
        }
      );
    } catch (error) {
      handleError();
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const sendMessage = createAsyncThunk(
  'user/send_message',
  async (abortController: AbortController, { dispatch, getState }) => {
    const {
      chatBot: { message, chatHistory }
    } = getState() as RootState;
    dispatch(updateAiGeneratedContent(EMPTY_STRING));
    dispatch(updateChatBotState(ChatBotState.WAITING));
    const currentMessage = { role: ChatRoles.USER, content: message };
    const body = JSON.stringify({
      chat: [...chatHistory, currentMessage],
      language: 'english'
    });
    dispatch(updateChatHistory(currentMessage));
    // This is similar to an EventSource, but allows
    // POST requests and headers
    const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
    const headers = {
      Accept: 'text/event-stream',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    try {
      dispatch(updateIsChatBotGenerating(true));
      await fetchEventSource(`${BASE_URL}${Endpoints.AI_CHAT}`, {
        body,
        headers,
        method: 'POST',
        signal: abortController.signal,
        onmessage: ({ data }) => {
          if (data === OPEN_AI_RESPONSE_DATA.DONE) {
            dispatch(updateIsChatBotGenerating(false));
          } else if (data && data !== OPEN_AI_RESPONSE_DATA.DONE) {
            // This is a new word or chunk from the API stream response
            dispatch(updateChatBotState(ChatBotState.LOADING));
            dispatch(updateAiGeneratedContent(data));
          }
        }
      });
    } catch (error) {
      dispatch(updateChatBotState(ChatBotState.IDLE));
      dispatch(updateIsChatBotGenerating(false));
      dispatch(
        updateError({
          message: 'errors.couldnt_generate_chat_bot_message',
          status: HTTP_STATS_CODE.NOT_FOUND
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserConsent = createAsyncThunk(
  'user/update_user_consent',
  async (consent_type: string, { dispatch }) => {
    try {
      let payload = {};
      if (consent_type === CONSENT_OPTION.TERMS_OF_SERVICE) {
        dispatch(updateIsUpdatingUserPrivacyPolicy(true));
        payload = DEFAULT_CONSENT_PAYLOAD.TERMS_OF_SERVICE;
      }
      const { status } = await focusBearApi.put(
        Endpoints.USER_CONSENT,
        payload
      );
      if (status !== HTTP_STATS_CODE.SUCCESS) {
        dispatch(updateIsUpdatingUserPrivacyPolicy(false));
        toast.warning(i18n.t('consent_error.could_not_update_privacy_policy'));
      } else {
        dispatch(updateShowPrivacyPolicyModal(false));
      }
      return status === HTTP_STATS_CODE.SUCCESS;
    } catch (error) {
      dispatch(updateIsUpdatingUserPrivacyPolicy(false));
      toast.error(i18n.t('consent_error.could_not_update_privacy_policy'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateUserAccessRequest = createAsyncThunk(
  'user/update_user_access_request',
  async (access_reason: string, { dispatch, getState }) => {
    try {
      const {
        admin: { accessRequestedUserId, skip }
      } = getState() as RootState;
      dispatch(updateIsAccessRequesting(true));
      const { status } = await focusBearApi.post(
        Endpoints.USER_ACCESS_REQUEST,
        {
          access_reason
        }
      );
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(updateIsAccessRequestGranted(true));
        dispatch(updateIsAccessRequesting(false));
        dispatch(updateShowRequestDetailsModal(false));
        dispatch(getUserInfoAndFocusModes(accessRequestedUserId));
        dispatch(
          getActivitiesByUserIdOrStripeIdOrActivityType({
            user_id: accessRequestedUserId,
            page_num: (skip + PAGINATE_ITEMS) / PAGINATE_ITEMS
          })
        );
      } else {
        toast.warning(i18n.t('could_not_access_user_data'));
        dispatch(updateShowPrivacyPolicyModal(false));
      }
    } catch (error) {
      dispatch(updateIsUpdatingUserPrivacyPolicy(false));
      toast.error(i18n.t('could_not_access_user_data'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getCompletedActivityDaySummary = createAsyncThunk(
  'setting/get_completed_activity_day_summary',
  async (timezone: string, { dispatch }) => {
    try {
      dispatch(updateIsDailySummaryFetching(true));
      const { data } = await focusBearApi.get(
        Endpoints.COMPLETED_ACTIVITY_DAY_SUMMARY,
        {
          params: {
            timezone
          }
        }
      );
      return data ?? null;
    } catch (error) {
      dispatch(updateIsDailySummaryFetching(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateLongTermGoals = createAsyncThunk(
  'user/update_long_term_goals',
  async (data: string[], { dispatch }) => {
    dispatch(updateIsLogTermGoalUpdating(true));
    try {
      const { status } = await focusBearApi.patch(
        Endpoints.USER_LONG_TERM_GOALS,
        {
          goals: data
        }
      );
      isRouteEmbedded && dispatch(updateIsLongTermGoalsModalOpened(false));
      if (window.location.pathname === ROUTES.WEBVIEW_ROUTINE_SUGGESTION) {
        dispatch(getLongTermGoals());
      } else {
        await dispatch(getUserDetails());
      }
      if (status === HTTP_STATS_CODE.SUCCESS) {
        return data;
      } else {
        toast.warning(i18n.t('could_not_update_long_term_goals'));
      }
    } catch (error) {
      toast.error(i18n.t('could_not_update_long_term_goals'));
      dispatch(updateIsLogTermGoalUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getWeeklyFocusBlockSummary = createAsyncThunk(
  'user/get_weekly_focus_block_summary',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsWeeklyFocusBlockSummaryUpdating(true));
      const { data } = await focusBearApi.get(
        Endpoints.USER_WEEKLY_FOCUS_BLOCK_SUMMARY
      );
      return data ?? [];
    } catch (error) {
      dispatch(updateIsWeeklyFocusBlockSummaryUpdating(false));
      toast.warning(i18n.t('could_not_update_weekly_focus_summary'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateLeaderBoardUsername = createAsyncThunk(
  'user/updateLeaderBoardUsername',
  async (username: string, { dispatch }) => {
    try {
      dispatch(updateIsUsernameUpdating(true));
      const { status } = await focusBearApi.put(Endpoints.USER_USERNAME, {
        username
      });
      dispatch(updateIsUsernameUpdating(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        toast.success(i18n.t('username_updated_successfully'));
        dispatch(updateUsername(username));
        dispatch(updateIsLeaderBoardFetching(true));
        dispatch(
          getUserStatsLeaderBoard({
            streak_type: EMPTY_STRING,
            limit: LEADER_BOARD.STAT_LIMIT
          })
        );
      } else {
        toast.error(i18n.t('could_not_update_username'));
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === HTTP_STATS_CODE.BAD_REQUEST) {
        toast.error(
          i18n.t('could_not_update_offensive_username', { username })
        );
      } else {
        toast.error(i18n.t('could_not_update_username'));
      }
      dispatch(updateIsUsernameUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const requestUserData = createAsyncThunk(
  'user/request_user_data',
  async (lang: string, { dispatch }) => {
    try {
      dispatch(updateIsRequestDataProcessing(true));
      const { status } = await focusBearApi.get(Endpoints.USER_DATA, {
        params: {
          language: lang
        }
      });
      dispatch(updateIsRequestDataProcessing(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(updateIsRequestDataConfirmationModalShown(false));
        toast.success(i18n.t('user_data_success'));
      } else {
        toast.warning(i18n.t('could_not_process_your_data'));
      }
    } catch (error) {
      dispatch(updateIsRequestDataProcessing(false));
      toast.error(i18n.t('could_not_process_your_data'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  'user/delete_user_account',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsAccountDeleting(true));
      const { status } = await focusBearApi.delete(Endpoints.USER_DATA);
      dispatch(updateIsAccountDeleting(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(updateIsAccountDeleted(true));
      } else {
        toast.error(i18n.t('could_not_process_the_request'));
      }
    } catch (error) {
      dispatch(updateIsAccountDeleting(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getWeeklyStats = createAsyncThunk(
  'setting/get_weekly_status',
  async (_, { dispatch }) => {
    try {
      const weeklySummaryStats = initWeeklySummaryStats(NUMBER_OF_DAYS);
      const { data: focus_blocks } = await focusBearApi.get(
        Endpoints.WEEKLY_FOCUS_BLOCK_SUMMARY
      );
      const { data: completed_activities } = await focusBearApi.get(
        Endpoints.WEEKLY_COMPLETED_ACTIVITY_SUMMARY
      );
      const { data: daily_summary }: { data: DailySummaryStatsType[] } =
        await focusBearApi.get(Endpoints.USER_STATS_DAILY_SUMMARY);
      const weeklyCompletedFocusBlocks = updateWeeklySummaryStats(
        weeklySummaryStats,
        focus_blocks
      );
      const weeklyCompletedActivities = updateWeeklySummaryStats(
        weeklySummaryStats,
        completed_activities,
        false
      );
      return (
        Array.from({ length: NUMBER_OF_DAYS }, (_, index) => ({
          ...weeklyCompletedActivities[index],
          completed_focus_modes:
            weeklyCompletedFocusBlocks[index].completed_focus_modes,
          ...daily_summary[index]
        })) ?? []
      );
    } catch (error) {
      dispatch(updateIsWeeklyStatsUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const saveUserFeedback = createAsyncThunk(
  'user/save_user_feedback',
  async (
    { rating, feedback, metadata, operating_system }: UserFeedbackPayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateIsSavingUserFeedback(true));
      const { status } = await focusBearApi.post(
        Endpoints.USER_FEEDBACK,
        {
          rating,
          feedback,
          metadata
        },
        {
          headers: {
            operating_system
          }
        }
      );
      dispatch(updateIsSavingUserFeedback(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(updateShowThankYouModal(true));
      } else {
        toast.error(i18n.t('could_not_process_the_request'));
      }
    } catch (error) {
      toast.error(i18n.t('could_not_process_the_request'));
      dispatch(updateIsSavingUserFeedback(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getLocalDeviceSettings = createAsyncThunk(
  'user/get_user_local_device_settings',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsLocalDeviceSettingUpdating(true));
      const { data } = await focusBearApi.get(
        Endpoints.USER_LOCAL_DEVICE_SETTINGS
      );
      return data;
    } catch (error) {
      dispatch(updateIsLocalDeviceSettingUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateLocalDeviceSettings = createAsyncThunk(
  'user/update_user_local_device_settings',
  async (payload: LocalDeviceSetting, { dispatch }) => {
    try {
      dispatch(updateIsLocalDeviceSettingUpdating(true));
      const { data } = await focusBearApi.put(
        Endpoints.USER_LOCAL_DEVICE_SETTINGS,
        payload
      );
      return data;
    } catch (error) {
      toast.error(i18n.t('could_not_process_the_request'));
      dispatch(updateIsLocalDeviceSettingUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getLongTermGoals = createAsyncThunk(
  'user/get_long_term_goals',
  async (_, { dispatch }) => {
    dispatch(updateIsLogTermGoalUpdating(true));
    try {
      const { data } = await focusBearApi.get(Endpoints.USER_LONG_TERM_GOALS);
      return data ?? [];
    } catch (error) {
      dispatch(updateIsLogTermGoalUpdating(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
