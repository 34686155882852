import {
  POST_HOG_EVENT,
  TAB_INDEX,
  TO_DO_PLAYER_SEARCH_DEBOUNCE_DELAY
} from 'constants/general';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updatePlayerIsReadyToSendUpdates,
  updatePlayerTasks
} from 'store/reducer/to-do/slice';
import {
  formatToDoPlayerTaskTimeConstraints,
  getToDoDefaultStatusLabel,
  getTodoPlayerTaskDuration,
  isTaskExistsInToDoPlayer
} from 'utils/support';
import { ToDoTask } from 'interfaces/commonInterface';
import { useContext } from 'react';
import { NewTaskModalContext } from '.';
import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, TO_DO_STATUS } from 'constants/enum';
import {
  searchResultSelector,
  searchResultsSelector
} from 'store/reducer/to-do/selectors';
import { useDebounce } from 'usehooks-ts';
import { captureEvents } from 'utils/events';

export const SearchResult = ({ task }: { readonly task: ToDoTask }) => {
  const dispatch = useAppDispatch();
  const { player, userId, userEmail } = useAppSelector(searchResultSelector);

  const handleClick = () => {
    const tasks = formatToDoPlayerTaskTimeConstraints([
      ...player.tasks,
      {
        ...task,
        startTime: '',
        endTime: '',
        focusedDuration: 0,
        duration: getTodoPlayerTaskDuration(
          player.tasks.length,
          player.total_duration,
          task.duration
        )
      }
    ]);
    dispatch(updatePlayerTasks(tasks));
    dispatch(updatePlayerIsReadyToSendUpdates(true));
    captureEvents(
      POST_HOG_EVENT.TODO_PLAYER_EXISTING_TASK_ADDED_FROM_ADD_TASK_UI,
      userEmail,
      {
        userId
      }
    );
  };

  const shouldAllowAddToPlayerList = !isTaskExistsInToDoPlayer(
    task.id,
    player.tasks
  );

  return (
    <div
      className={classNames(
        'flex items-center gap-4 border-b border-gray-400 py-2 px-3 relative',
        {
          'cursor-pointer': shouldAllowAddToPlayerList,
          'cursor-default': !shouldAllowAddToPlayerList
        }
      )}
      onClick={(event) => {
        event.stopPropagation();
        shouldAllowAddToPlayerList && handleClick();
      }}
      role='button'
      onKeyDown={() => {
        //@Description: suppress eslint jsx-a11y/click-events-have-key-events
      }}
      tabIndex={TAB_INDEX.TO_DO_SEARCH_RESULT}
    >
      <p className='text-sm font-medium'>{task.title}</p>
      {task.status !== TO_DO_STATUS.COMPLETED && shouldAllowAddToPlayerList && (
        <button
          onClick={handleClick}
          className='btn-primary text-[0.5rem] px-1.5 leading-3'
        >
          {t('add')}
        </button>
      )}
      {task.status === TO_DO_STATUS.COMPLETED && (
        <small className='bg-green-600 text-white text-[0.6rem] px-1.5 py-0.5 leading-3 rounded'>
          {getToDoDefaultStatusLabel(task.status)}
        </small>
      )}
    </div>
  );
};

const SearchResults = ({
  handleCreateTask
}: {
  handleCreateTask: () => void;
}) => {
  const {
    newTaskModal: { searchedTitle }
  } = useContext(NewTaskModalContext);
  const { searchResults, isSearchingToDos, isCreatingToDo } = useAppSelector(
    searchResultsSelector
  );
  const searchedToDoTitle = useDebounce(
    searchedTitle,
    TO_DO_PLAYER_SEARCH_DEBOUNCE_DELAY
  );

  return (
    <>
      {searchResults?.length ? (
        searchResults?.map((task) => <SearchResult key={task.id} task={task} />)
      ) : (
        <div className='flex flex-col items-center gap-3 m-auto'>
          {searchedToDoTitle &&
            searchResults &&
            !searchResults.length &&
            !isCreatingToDo &&
            !isSearchingToDos && (
              <p className='flex flex-wrap items-center justify-center gap-1 text-sm'>
                {t('no_matching_tasks_found')}
              </p>
            )}
          {isCreatingToDo ? (
            <span className='w-4 h-4 rounded-full border-t-2 border-t-focusBearText animate-spin ml-2'></span>
          ) : (
            searchedToDoTitle &&
            !isSearchingToDos && (
              <ButtonFocusBear
                size={BTN_FB_SIZE.SMALL}
                title={t('create_a_new_task', { task: searchedToDoTitle })}
                disabled={isCreatingToDo}
                onClick={handleCreateTask}
                className='w-9/12 truncate'
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default SearchResults;
