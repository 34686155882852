import CloseCircle from 'assets/icons/CloseCircle';
import classNames from 'classnames';
import Button from 'components/common/buttons/Button';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import CheckBox from 'components/common/CheckBox';
import TextInput from 'components/common/inputs/TextInput';
import ModalOverlay from 'components/shared/ModalOverlay';
import COLOR from 'constants/color';
import { BTN_FB_SIZE } from 'constants/enum';
import { t } from 'i18next';
import { FC, PropsWithChildren, useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  routineAvailableGoalsSelector,
  themeSelector
} from 'store/reducer/setting/selectors';
import { updateRoutineSuggestionPayload } from 'store/reducer/setting/slice';
import { updateLongTermGoals } from 'store/reducer/user/extra';
import { isDarkModeActivated } from 'utils/validation';
import { RoutineGoalsContext } from '.';
import { EMPTY_STRING } from 'constants/general';

const Wrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={classNames(
        'relative top-1/2 md:top-1/3 -translate-y-1/2 m-auto flex w-11/12 sm:w-10/12 md:w-3/4 h-fit flex-col gap-1 p-5 sm:p-10 rounded-lg animate-fadeIn',
        {
          'bg-gray-600': shouldActivateDarkMode,
          'bg-sunRay': !shouldActivateDarkMode
        }
      )}
    >
      {children}
    </div>
  );
};

const AvailableGoalsModal: FC = () => {
  const dispatch = useAppDispatch();
  const { details, isLogTermGoalUpdating, routineSuggestionPayload } =
    useAppSelector(routineAvailableGoalsSelector);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const { routineData, setRoutineData } = useContext(RoutineGoalsContext);

  function handleAddGoal(goal: string) {
    const selectedGoals = routineData.selectedGoals.includes(goal)
      ? routineData.selectedGoals.filter((prevGoal) => prevGoal !== goal)
      : [...routineData.selectedGoals, goal];
    setRoutineData?.((prev) => ({
      ...prev,
      selectedGoals
    }));
    dispatch(
      updateRoutineSuggestionPayload({
        ...routineSuggestionPayload,
        user_goals: [...selectedGoals]
      })
    );
  }

  return (
    <ModalOverlay styles='bg-transparent'>
      <Wrapper>
        <p className='text-sm sm:text-base md:text-lg'>
          {t(
            'routine_suggestion.are_there_other_goals_you_want_to_achieve_with_focus_bear'
          )}
        </p>
        <div className='w-full h-[60vh] md:h-[40vh] sm:h-fit flex flex-wrap justify-evenly gap-x-2 gap-y-2 rounded-lg shadow-md p-5 overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFirst border border-sunRayFirst'>
          {routineData.availableGoals.map((goal, idx) => (
            <CheckBox
              key={goal + idx}
              checked={routineData.selectedGoals.includes(goal)}
              onChange={() => handleAddGoal(goal)}
              label={goal}
              labelStyles={classNames(
                'w-fit max-w-full h-fit flex gap-1 items-start rounded-md px-2 py-1 shadow-md text-xs sm:text-sm my-2 cursor-pointer',
                {
                  'bg-gray-600 hover:bg-gray-600/80': shouldActivateDarkMode,
                  'bg-whiteLaceFirst hover:bg-whiteLaceSecond ':
                    !shouldActivateDarkMode
                }
              )}
              inputStyles='mt-1'
            />
          ))}
        </div>
        <div className='w-fit h-fit flex items-center gap-2 self-center mt-3'>
          <TextInput
            disabled={isLogTermGoalUpdating}
            value={routineData.newGoal}
            onChange={({ target: { value } }) => {
              setRoutineData?.((prev) => ({ ...prev, newGoal: value }));
            }}
            className={classNames(
              'min-w-[25vw] outline-none rounded-md shadow px-2 py-1 md:py-2 text-xs text-black',
              {
                'bg-gray-400 focus:bg-gray-400/80': shouldActivateDarkMode,
                'bg-white': !shouldActivateDarkMode
              }
            )}
            placeholder={t('routine_suggestion.long_term_goal')}
          />
          {isLogTermGoalUpdating ? (
            <span className='w-4 h-4 rounded-full border-gray-700 border-t animate-spin'></span>
          ) : (
            <ButtonFocusBear
              size={BTN_FB_SIZE.SMALL}
              onClick={async () => {
                const response = await dispatch(
                  updateLongTermGoals([
                    ...(details.long_term_goals ?? []),
                    routineData.newGoal
                  ])
                );
                response.meta.requestStatus === 'fulfilled' &&
                  setRoutineData?.((prev) => ({
                    ...prev,
                    newGoal: EMPTY_STRING
                  }));
              }}
              title={t('save')}
            />
          )}
        </div>
        <Button
          className='bg-sunRay rounded-full w-fit absolute -top-2 -right-2'
          icon={<CloseCircle fill={COLOR.BLACK} fillDarkMode={COLOR.RED} />}
          onClick={() =>
            setRoutineData?.((prev) => ({ ...prev, showAvailableGoals: false }))
          }
        />
      </Wrapper>
    </ModalOverlay>
  );
};

export default AvailableGoalsModal;
