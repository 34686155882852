import { HABIT_QUESTIONS, ROUTINE, TAB } from 'constants/general';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import ShutdownTime from './ShutdownTime';
import TabBodyHeaderWidgets from './TabBodyHeaderWidgets';
import { tabBodyHeaderSelector } from 'store/reducer/setting/selectors';

const TabBodyHeaderWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit flex items-center relative py-1 sm:py-2'>
    {children}
  </div>
);

const TabBodyHeader = ({ title }: { title: string }) => {
  const { t } = useTranslation();
  const { tabs, activeTabIndex, customRoutine } = useAppSelector(
    tabBodyHeaderSelector
  );
  const shouldDisplayFilterWidget = [
    TAB.MORNING,
    TAB.MICRO_BREAKS,
    TAB.EVENING,
    TAB.ACTIVITY
  ].includes(tabs[activeTabIndex]);

  return (
    <TabBodyHeaderWrapper>
      {customRoutine !== ROUTINE.EVENING && (
        <div className='w-[80%] h-fit flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 text-sm sm:text-xl'>
          {t(title)}
          {title === HABIT_QUESTIONS.EVENING_ROUTINE && <ShutdownTime />}
        </div>
      )}
      {shouldDisplayFilterWidget && <TabBodyHeaderWidgets />}
    </TabBodyHeaderWrapper>
  );
};

export default TabBodyHeader;
