import {
  EMPTY_STRING,
  LOCAL_STORAGE,
  MAC_APP_DATA_LOCATION,
  MOBILE_APP_EVENT,
  OK,
  PLATFORMS
} from 'constants/general';
import { store } from 'store';
import {
  initFocusPlayer,
  updateIncomingTasks,
  resetToDoPlayer
} from 'store/reducer/to-do/slice';
import {
  convertToSeconds,
  formatToDoPlayerTaskTimeConstraints,
  getLocalStorage,
  getTodoPlayerTaskDuration,
  isAlphaNumericInput,
  updateLocalStorage
} from './support';
import i18n from 'services/i18n';
import {
  AppMetadataType,
  RoutineSuggestionMetaData,
  SendDataToPlatformPayload,
  ToDoPlayerTask,
  ToDoTaskAppMetaDataType
} from 'interfaces';
import { toast } from 'react-toastify';
import {
  addInstalledApps,
  updateFeedback,
  updateFlags,
  updateFont,
  updateIsTokenAddedToInterceptor,
  updateIsUserOnboarding,
  updateLanguage,
  updatePlatform,
  updateRoutineSuggestionPayload,
  updateThemeMode
} from 'store/reducer/setting/slice';
import { FLAG, LanguageCodes, TO_DO_STATUS } from 'constants/enum';
import { addAccessTokenToInterceptor } from 'services/axios-config';
import { setFocusedModes } from 'store/reducer/user/slice';
import { DEFAULT_GOAL_SUGGESTIONS } from 'assets/data';
import * as Sentry from '@sentry/react';

export const init_focusBear_apps = (
  data: AppMetadataType | ToDoTaskAppMetaDataType
) => {
  const actions = [];
  const langCode =
    data.lang === (LanguageCodes.ES as string)
      ? LanguageCodes.ES
      : LanguageCodes.EN;
  i18n.changeLanguage(langCode);

  let flags = (data.flags ?? []) as FLAG[];
  if (flags?.length) {
    if (!flags.includes(FLAG.SIMPLE_MODE) && !flags.includes(FLAG.GEEK_MODE)) {
      flags = [...flags, FLAG.GEEK_MODE];
    }
  } else {
    flags = [FLAG.GEEK_MODE];
  }

  actions.push(
    ...[
      updateFlags(flags),
      updateLanguage(langCode),
      updateThemeMode(data.theme),
      updateFont(data.font),
      updatePlatform(data?.platform)
    ]
  );
  actions.forEach((action) => store.dispatch(action));
  if (data?.access_token) {
    updateLocalStorage(LOCAL_STORAGE.BEARER_TOKEN, data.access_token);
    addAccessTokenToInterceptor();
    store.dispatch(updateIsTokenAddedToInterceptor(true));
  } else {
    toast.warning(i18n.t('to_do_player.token_missing_warning'));
  }
  return OK;
};

export const loadSettingsData = () => {
  window.loadSettingsData = (data: AppMetadataType) => {
    //@Description: provides only a simple mode for mobile applications
    const metaData = {
      ...data,
      flags: [PLATFORMS.ANDROID, PLATFORMS.IOS, PLATFORMS.MOB].includes(
        data.platform ?? ''
      )
        ? [FLAG.SIMPLE_MODE]
        : data.flags
    };
    init_focusBear_apps(metaData);
    return OK;
  };

  window.LoadInstalledApps = (data: { installedApps: string[] }): string => {
    store.dispatch(addInstalledApps(data));
    return OK;
  };

  window.LoadFocusNames = (data: {
    focusNames: { name: string; id: string }[];
  }) => {
    store.dispatch(setFocusedModes(data));
  };
};

export const loadMetaDataForToDoPlayer = () =>
  (window.loadMetaDataForToDoPlayer = (data: ToDoTaskAppMetaDataType) => {
    init_focusBear_apps(data);
    const incoming_tasks = (data?.tasks ?? []) as ToDoPlayerTask[];
    const tasks = formatToDoPlayerTaskTimeConstraints(
      (incoming_tasks ?? [])
        ?.map((task) => ({
          ...task,
          duration: convertToSeconds(
            getTodoPlayerTaskDuration(
              incoming_tasks.length,
              data.total_duration,
              task.duration
            )
          )
        }))
        ?.filter((task) => task.status !== TO_DO_STATUS.COMPLETED)
    );
    store.dispatch(
      updateIsUserOnboarding(data?.isOnboarding === true ? true : false)
    );

    const brain_dump = decodeURIComponent(data.brain_dump ?? EMPTY_STRING);
    const intention = decodeURIComponent(data.intention ?? EMPTY_STRING);

    updateLocalStorage(
      LOCAL_STORAGE.BRIAN_DUMP_CONTENTS,
      isAlphaNumericInput(brain_dump) ? brain_dump : EMPTY_STRING
    );
    store.dispatch(
      initFocusPlayer({
        tasks,
        intention: isAlphaNumericInput(intention) ? intention : EMPTY_STRING,
        total_duration: data.total_duration
          ? convertToSeconds(data.total_duration)
          : undefined
      })
    );
    updateLocalStorage(LOCAL_STORAGE.TO_DO_PLAYER_TASKS, JSON.stringify(tasks));
    return OK;
  });

export const loadAccessTokenForToDo = () =>
  (window.loadAccessTokenForToDo = (data: ToDoTaskAppMetaDataType) => {
    store.dispatch(updateIncomingTasks(data?.tasks ?? []));
    init_focusBear_apps(data);
  });

export const loadAccessTokenForSurvey = () =>
  (window.loadAccessTokenForSurvey = (data: AppMetadataType) => {
    data?.feedback && store.dispatch(updateFeedback(data.feedback));
    init_focusBear_apps(data);
  });

export const getToDosFromPlayer = () =>
  (window.getToDosFromPlayer = () => {
    const latestTodoTasks = getLocalStorage(LOCAL_STORAGE.TO_DO_PLAYER_TASKS);
    return latestTodoTasks ? JSON.parse(latestTodoTasks) : [];
  });

export const loadAccessTokenForStats = () => {
  window.loadAccessTokenForStats = (data: AppMetadataType): string =>
    init_focusBear_apps(data);

  window.refreshStatsPage = () => {
    window.location.reload();
  };
};

export const loadMetaDataForSubscription = () => {
  window.loadMetaDataForSubscription = (data: AppMetadataType) =>
    init_focusBear_apps(data);
};

export const loadAccessTokenForCourse = () =>
  (window.loadAccessTokenForCourse = (metadata: AppMetadataType) =>
    init_focusBear_apps(metadata));

export const loadMetaDataForRoutineSuggestion = () =>
  (window.loadMetaDataForRoutineSuggestion = (
    data: RoutineSuggestionMetaData
  ) => {
    store.dispatch(
      updateRoutineSuggestionPayload({
        ...store.getState().setting.routineSuggestionPayload,
        user_goals: [
          ...(data?.long_term_goals?.length
            ? data.long_term_goals.filter(Boolean)
            : DEFAULT_GOAL_SUGGESTIONS.slice(0, 2))
        ]
      })
    );

    return init_focusBear_apps({ ...data, flags: [FLAG.SIMPLE_MODE] });
  });

export const loadMetaDataForEnrolledCourses = () =>
  (window.loadMetaDataForEnrolledCourses = (metadata: AppMetadataType) =>
    init_focusBear_apps(metadata));

export const loadMarketPageMetaData = () =>
  (window.loadMarketPageMetaData = (metadata: AppMetadataType) =>
    init_focusBear_apps(metadata));

export const loadAccessTokenForInspirationPage = () =>
  (window.loadAccessTokenForInspirationPage = (metadata: AppMetadataType) =>
    init_focusBear_apps(metadata));

export const sendDataToPlatform = (
  platformType: string,
  data: SendDataToPlatformPayload,
  mac_where: string,
  mobile_where?: string
) => {
  try {
    if (platformType === PLATFORMS.MAC) {
      switch (mac_where) {
        case MAC_APP_DATA_LOCATION.CLOSE_SURVEY_UI:
          window.webkit.messageHandlers.close_survey_ui.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.CLOSE_TODO_TASK_UI:
          window.webkit.messageHandlers.close_todo_task_ui.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.START_FOCUS_MODE:
          window.webkit.messageHandlers.get_selected_tasks.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.END_FOCUS_MODE:
          window.webkit.messageHandlers.submit_completed_tasks.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.USER_SETTINGS_UPDATED:
          window.webkit.messageHandlers.detect_user_settings_change.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_NEW_TASK_FROM_TO_DO_PLAYER:
          window.webkit.messageHandlers.send_new_task_added_to_todo_player.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_SUGGESTED_ACTIVITIES:
          window.webkit.messageHandlers.send_suggested_activities.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_SELECTED_HABIT_PACK_ID:
          window.webkit.messageHandlers.send_installed_habit_pack_id.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_USER_SUBSCRIPTION_STATUS:
          window.webkit.messageHandlers.send_user_subscription_status.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_BRAIN_DUMP_CONTENT:
          window.webkit.messageHandlers.send_brain_dump_content.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.ROUTINE_SUGGESTIONS_STEP:
          window.webkit.messageHandlers.routine_suggestions_step.postMessage(
            JSON.stringify(data)
          );
          break;
        case MAC_APP_DATA_LOCATION.SEND_INTENTION:
          window.webkit.messageHandlers.send_intention.postMessage(
            JSON.stringify(data)
          );
          break;
        default:
          window.webkit.messageHandlers.setting_saved.postMessage(
            JSON.stringify(data)
          );
      }
    }

    if (
      [PLATFORMS.MOB, PLATFORMS.ANDROID, PLATFORMS.IOS].includes(platformType)
    ) {
      switch (mobile_where) {
        case MOBILE_APP_EVENT.SUGGESTED_ACTIVITIES_INSTALLED:
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: MOBILE_APP_EVENT.SUGGESTED_ACTIVITIES_INSTALLED,
              data
            })
          );
          break;
        case MOBILE_APP_EVENT.SEND_SELECTED_HABIT_PACK_ID:
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: MOBILE_APP_EVENT.SEND_SELECTED_HABIT_PACK_ID,
              data
            })
          );
          break;
        case MOBILE_APP_EVENT.EDIT_HABITS_ENABLED:
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: MOBILE_APP_EVENT.EDIT_HABITS_ENABLED,
              data
            })
          );
          break;
        default:
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    }

    platformType === PLATFORMS.WIN && window.chrome.webview.postMessage(data);
  } catch (error) {
    Sentry.captureException(JSON.stringify(error));
  }
};

export const loadMetaDataFoGetSupport = () =>
  (window.loadMetaDataFoGetSupport = (metadata: AppMetadataType) =>
    init_focusBear_apps(metadata));

export const stopToDoPlayer = () =>
  (window.stopToDoPlayer = () => store.dispatch(resetToDoPlayer()));
