import PlusCircle from 'assets/icons/PlusCircle';
import COLOR from 'constants/color';
import { DetailedHTMLProps, ButtonHTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newHabit } from 'store/reducer/setting/slice';
import { getNewHabitProps } from 'utils/support';
import _ from 'lodash';
import { addHabitButtonSelector } from 'store/reducer/setting/selectors';

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  routine: string;
  styles?: string;
}

const AddHabitButton: React.FC<ButtonProps> = ({
  routine,
  styles = 'bg-focusBearText/60 hover:scale-105 duration-150 ease-in-out shadow-md',
  ...rest
}: ButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tabs, currentSettings, activeTabIndex, customRoutine, routines } =
    useAppSelector(addHabitButtonSelector);

  const [activity_type, sequence_id] = useMemo(
    () =>
      getNewHabitProps(
        tabs,
        activeTabIndex,
        _.cloneDeep(currentSettings),
        customRoutine,
        routines
      ),
    [tabs, activeTabIndex, currentSettings, customRoutine, routines]
  );

  return (
    <div className='w-fit h-full flex flex-col items-center justify-center gap-4 sm:gap-6 animate-fadeIn'>
      <p className='text-sm sm:text-base md:text-lg font-semibold italic text-center'>
        {t('make_at_least_one_new_habit_a_part_of_your_routine', { routine })}
      </p>
      <button
        onClick={() =>
          dispatch(
            newHabit({
              type: activity_type,
              sequence_id
            })
          )
        }
        className={`w-[80vw] xs:w-fit flex items-center justify-center gap-2 rounded-lg py-1.5 xs:py-2 px-4 text-xs sm:text-sm md:text-base lg:text-lg ${styles}`}
        {...rest}
      >
        <PlusCircle
          styles='min-w-[1rem] w-4 sm:w-5 h-auto'
          fill={COLOR.BLACK}
        />
        {t('add_your_first_routine_habit', { routine })}
      </button>
    </div>
  );
};

export default AddHabitButton;
