import { TO_DO_STATUS } from 'constants/enum';
import { t } from 'i18next';
import { ToDoPlayerTask } from 'interfaces';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import { playerTaskStatusSelector } from 'store/reducer/to-do/selectors';
import {
  updatePlayerIsReadyToSendUpdates,
  updatePlayerTaskCompletion
} from 'store/reducer/to-do/slice';
import { getTodoTaskStatus } from 'utils/support';

const PlayerTaskStatus = ({
  task,
  selectStyles = 'w-full md:w-fit text-xs px-2 py-1.5 rounded outline-none border-b border-gray-400 cursor-pointer disabled:cursor-not-allowed animate-fadeIn'
}: {
  task: ToDoPlayerTask;
  selectStyles?: string;
}) => {
  const dispatch = useAppDispatch();
  const { selectedTask, tasksPlayedIds, tasks } = useAppSelector(
    playerTaskStatusSelector
  );
  const [status, setStatus] = useState({
    value: task.status,
    isUpdating: false
  });
  const taskStatusOptions = getTodoTaskStatus(task);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      value:
        selectedTask && selectedTask.id === task.id
          ? selectedTask.status
          : task.status
    }));
  }, [task, selectedTask]);

  const handleTaskStatus = async (value: string) => {
    setStatus({ value, isUpdating: true });
    const previousStatus = task.status;
    const { duration, focusedDuration, startTime, endTime, ...rest } = task;
    const response = await dispatch(
      updateToDoStatus({
        todoTask: { ...rest, status: value },
        isTodoPlayer: true
      })
    );
    if (response.meta.requestStatus === 'rejected') {
      setStatus((prev) => ({ ...prev, value: previousStatus }));
      toast.error(
        t('to_do_procrastinate.could_not_update_the_task', {
          title: task.title
        })
      );
    } else {
      const isTaskCompleted = status.value === TO_DO_STATUS.COMPLETED;
      dispatch(
        updatePlayerTaskCompletion({
          tasks: tasks?.map((playerTask) =>
            playerTask.id === task.id
              ? { ...playerTask, status: value }
              : playerTask
          ),
          tasksPlayedIds: isTaskCompleted
            ? [...tasksPlayedIds, task.id]
            : tasksPlayedIds,
          completedTask: null,
          isSelectedTaskCompleted: false,
          selectedTask:
            value === TO_DO_STATUS.IN_PROGRESS
              ? { ...task, status: TO_DO_STATUS.IN_PROGRESS }
              : undefined
        })
      );
      setStatus((prev) => ({ ...prev, isUpdating: false }));
      dispatch(updatePlayerIsReadyToSendUpdates(true));
    }
  };

  return (
    <select
      disabled={status.isUpdating}
      value={status.value}
      onChange={({ target: { value } }) => handleTaskStatus(value)}
      className={selectStyles}
    >
      {taskStatusOptions.map((status) => (
        <option
          key={status.value}
          value={status.value}
          className='text-xs cursor-pointer'
        >
          {status.label}
        </option>
      ))}
    </select>
  );
};

export default PlayerTaskStatus;
