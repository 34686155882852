import cover from 'assets/images/bear.png';
import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import {
  BTN_FB_SIZE,
  BTN_FB_VARIANT,
  LessonCompletionStatus
} from 'constants/enum';
import {
  ITEM_INDEX_NOT_FOUND,
  OTHER_COURSES_TAB,
  POST_HOG_EVENT
} from 'constants/general';
import { t } from 'i18next';
import { Course } from 'interfaces';
import { UserDetailsTypes } from 'interfaces/userInterface';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createCourseEnrolment } from 'store/reducer/course/extra';
import { courseItemSelector } from 'store/reducer/course/selectors';
import {
  updateCourseDetails,
  updateShowCourseLessonsModal
} from 'store/reducer/course/slice';
import { captureEvents } from 'utils/events';
import { isDarkModeActivated } from 'utils/validation';

const getCourseInfo = (course: Course, userInfo: UserDetailsTypes) => {
  const isCourseCompleted =
    course?.enrollments?.findIndex(
      ({ user_id, course_id, finished }) =>
        finished && course.id === course_id && user_id === userInfo?.id
    ) !== ITEM_INDEX_NOT_FOUND;
  const isSelfTaught =
    isCourseCompleted &&
    course.lessonCompletions?.some(
      (lessonCompletion) =>
        lessonCompletion.course_id === course.id &&
        lessonCompletion.status ===
          (LessonCompletionStatus.SELF_TAUGHT as string)
    );
  const isCourseInProgress =
    !isCourseCompleted &&
    course.lessonCompletions?.some(
      (lessonCompletion) => lessonCompletion.course_id === course.id
    );
  return { isCourseCompleted, isCourseInProgress, isSelfTaught };
};

const CourseItem = ({ course }: { course: Course }) => {
  const dispatch = useAppDispatch();
  const {
    isCreatingCourseEnrolment,
    selectedCourse,
    activeTab,
    userInfo,
    themeMode
  } = useAppSelector(courseItemSelector);
  const [shouldVisibleOtherCoursesTab, shouldActivateDarkMode] = [
    activeTab === OTHER_COURSES_TAB.index,
    isDarkModeActivated(themeMode)
  ];

  const { isCourseCompleted, isCourseInProgress, isSelfTaught } = useMemo(
    () => getCourseInfo(course, userInfo),
    [course, userInfo]
  );

  const handleClick = useCallback(() => {
    if (shouldVisibleOtherCoursesTab) {
      captureEvents(
        POST_HOG_EVENT.TUTORIAL_COURSE_ENROLL_BUTTON_CLICKED,
        userInfo.email,
        { course_id: course.id }
      );
      dispatch(createCourseEnrolment(course.id));
    } else {
      if (!isCourseCompleted) {
        dispatch(updateCourseDetails(course));
        isCourseInProgress && dispatch(updateShowCourseLessonsModal(true));
      }
    }
  }, [isCourseCompleted]);

  const getBtnTitle = useCallback(() => {
    if (shouldVisibleOtherCoursesTab) {
      return t('course.enrol');
    } else if (isCourseCompleted) {
      return isSelfTaught ? t('course.self_taught') : t('course.completed');
    } else if (isCourseInProgress) {
      return t('course.continue');
    } else {
      return t('course.view');
    }
  }, [course, userInfo]);

  return (
    <div className='w-full h-fit sm:h-32 flex flex-col sm:flex-row shadow-md relative'>
      <img
        src={cover}
        alt={course.name}
        className={classNames(
          'w-full sm:w-[35%] h-[15vh] sm:h-full rounded-l-md object-contain p-4 select-none',
          {
            'bg-orange-200': !shouldActivateDarkMode,
            'bg-gray-500': shouldActivateDarkMode
          }
        )}
      />
      <div
        className={classNames(
          'w-full sm:w-[65%]  flex flex-col text-sm gap-2 p-2 rounded-r-md',
          {
            'bg-gray-100': !shouldActivateDarkMode,
            'bg-gray-400': shouldActivateDarkMode
          }
        )}
      >
        <p className='w-full h-fit text-blue-900 text-sm font-semibold leading-5 line-clamp-2'>
          {course.name}
        </p>
        <p className='line-clamp-2 leading-4 text-xs text-ellipsis text-black'>
          {course.description}
        </p>
        <p className='w-fit text-blue-600 text-[0.65rem] mt-auto ml-auto'>
          {moment(course.created_at).format('MMM DD, YYYY')}
        </p>
      </div>

      {selectedCourse?.id === course.id && isCreatingCourseEnrolment ? (
        <div className='absolute top-2 left-2 w-4 h-4 border-t-2 border-whiteLace rounded-full animate-spin'></div>
      ) : (
        <ButtonFocusBear
          disableDarkMode
          disabled={isCreatingCourseEnrolment}
          variant={
            shouldVisibleOtherCoursesTab
              ? BTN_FB_VARIANT.SECONDARY
              : BTN_FB_VARIANT.PRIMARY
          }
          title={getBtnTitle()}
          additionalStyles='absolute top-1 left-1 text-[0.65rem]'
          onClick={handleClick}
          size={BTN_FB_SIZE.SMALL}
        />
      )}
    </div>
  );
};

export default CourseItem;
