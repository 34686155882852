import { BTN_FB_SIZE, FLAG, THEME_OPTION } from 'constants/enum';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import MoreOptions from './MoreOptions';
import MorningAdvancedModal from './routine/MorningAdvancedModal';
import {
  resetFilters,
  updateFilterIsSettingToolbarShow
} from 'store/reducer/filter/slice';
import { updateActiveTabIndex } from 'store/reducer/setting/slice';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import {
  PLATFORMS,
  ROUTINE,
  SCREEN,
  SETTING_TAB_INDEX,
  TAB
} from 'constants/general';
import UnlockHabitPackModal from 'components/UnlockHabitPackModal';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getUserSettings } from 'store/reducer/setting/extra';
import NerdFace from 'assets/icons/NerdFace';
import SmilingFaceWithSunglasses from 'assets/icons/SmilingFaceWithSunglasses';
import AlarmClock from 'assets/icons/AlarmClock';
import SunSeason from 'assets/icons/SunSeason';
import Moon from 'assets/icons/Moon';
import ShoppingBags from 'assets/icons/ShoppingBags';
import HotBeverage from 'assets/icons/HotBeverage';
import classNames from 'classnames';
import { t } from 'i18next';
import { SettingsPageContext } from '..';
import { getSettingPageTabContent } from 'utils/settingsTab';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { tabBodySelector } from 'store/reducer/setting/selectors';
import { useWindowSize } from 'usehooks-ts';

const TabBody = () => {
  const {
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags
  } = useAppSelector(tabBodySelector);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const settingPageContext = useContext(SettingsPageContext);
  const [showTabContentsForMobileDevices, setShowTabContentsForMobileDevices] =
    useState((settingPageContext.width ?? width) <= SCREEN.WIDTH.SMALL);
  const isGeekMode = flags.includes(FLAG.GEEK_MODE);
  const shouldActiveMobileView =
    (settingPageContext.width ?? width) <= SCREEN.WIDTH.SMALL;

  useEffect(() => {
    setShowTabContentsForMobileDevices(!shouldActiveMobileView);
  }, [settingPageContext?.width, width]);

  const routine_tabs = useMemo(() => {
    if (!customRoutine && PLATFORMS.WEB !== platform) {
      //@Description, break temporary disabled for mobile apps
      if (isGeekMode) {
        return tabs;
      } else if (
        [PLATFORMS.ANDROID, PLATFORMS.IOS, PLATFORMS.MOB].includes(platform)
      ) {
        return tabs.filter((tab) => tab !== TAB.MICRO_BREAKS);
      }
      return tabs.filter((tab) => tab !== TAB.HABIT_PACK);
    } else {
      return tabs;
    }
  }, [tabs, platform, flags]);

  useEffect(() => {
    const isHabitPackInstallOrUninstalled =
      [
        SETTING_TAB_INDEX.EVENING_ROUTINE,
        SETTING_TAB_INDEX.HABIT_PACKS
      ].includes(activeTabIndex) && !isSettingLoaded; //@Description: this change needs to be reverted back once the mobile apps have the micro-break tab active
    isHabitPackInstallOrUninstalled && dispatch(getUserSettings());
  }, [isSettingLoaded, activeTabIndex]);

  const getTabIcon = useCallback(
    (tabTitle: string) => {
      switch (tabTitle) {
        case TAB.TIMING:
          return <AlarmClock />;
        case TAB.MORNING:
          return <SunSeason />;
        case TAB.MICRO_BREAKS:
          return <HotBeverage />;
        case TAB.EVENING:
          return <Moon />;
        case TAB.HABIT_PACK:
          return <ShoppingBags />;
        default:
          return null;
      }
    },
    [routine_tabs]
  );

  const isRoutineScheduleBlock = customRoutine === ROUTINE.TIMING;

  return (
    <>
      <Tabs
        selectedIndex={activeTabIndex}
        className='w-full h-[93%] flex flex-col py-2 sm:py-4 mb-4'
        onSelect={(selectedIndex) => {
          dispatch(updateActiveTabIndex(selectedIndex));
          dispatch(updateFilterIsSettingToolbarShow(false));
          dispatch(resetFilters());
          routine_tabs[selectedIndex] === TAB.HABIT_PACK &&
            dispatch(setCurrentViewedPack(null));
        }}
        selectedTabClassName={classNames(
          {
            'bg-gray-700': themeMode === THEME_OPTION.DARK,
            'bg-white': themeMode === THEME_OPTION.LIGHT
          },
          'border-t rounded-t-md'
        )}
      >
        {!isRoutineScheduleBlock && (
          <>
            {shouldActiveMobileView ? (
              <>
                {showTabContentsForMobileDevices ? null : (
                  <div className='w-full h-full flex flex-col gap-5 sm:gap-0 items-center justify-around'>
                    {routine_tabs.map((tab: string, index: number) => (
                      <button
                        key={tab}
                        onClick={() => {
                          dispatch(updateActiveTabIndex(index));
                          setShowTabContentsForMobileDevices(true);
                        }}
                        className='w-10/12 xs:w-8/12 text-sm py-3 md:py-4 px-8 rounded-tr-full rounded-full bg-sunRayFifth text-black shadow-md flex flex-col items-center justify-center gap-2 select-none'
                      >
                        {getTabIcon(tab)}
                        {t(`${tab}`)}
                      </button>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <TabList
                className={classNames('w-full min-h-[2rem] h-[7%] flex', {
                  'flex-col items-center gap-4': shouldActiveMobileView,
                  'items-center gap-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-700 overflow-y-hidden':
                    !shouldActiveMobileView
                })}
              >
                {routine_tabs.map((tab: string, index: number) => (
                  <Tab
                    data-test={
                      getSettingPageTabContent(
                        mode,
                        index,
                        customRoutine,
                        platform
                      ).testIds?.[index]
                    }
                    className={classNames(
                      'min-w-max w-fit text-sm md:text-base px-4 py-2 cursor-pointer outline-none border-gray-400',
                      {
                        'tracking-wider': platform !== PLATFORMS.WEB,
                        'tracking-normal': platform === PLATFORMS.WEB,
                        'border-b': activeTabIndex !== index
                      },
                      'flex items-center gap-1.5 select-none'
                    )}
                    key={tab}
                  >
                    {getTabIcon(tab)}
                    {t(`${tab}`)}
                  </Tab>
                ))}
                {platform !== PLATFORMS.WEB && (
                  <span className='px-4'>
                    {!customRoutine &&
                      (isGeekMode ? (
                        <NerdFace />
                      ) : (
                        <SmilingFaceWithSunglasses />
                      ))}
                  </span>
                )}
              </TabList>
            )}
          </>
        )}

        {(isRoutineScheduleBlock || showTabContentsForMobileDevices) &&
          routine_tabs.map((tab: string, index: number) => (
            <TabPanel
              key={tab}
              className={`${shouldActiveMobileView ? 'h-full' : 'h-[93%]'} ${index !== activeTabIndex && 'hidden'} relative p-2 animate-fadeIn`}
            >
              {!isRoutineScheduleBlock && shouldActiveMobileView ? (
                <ButtonFocusBear
                  onClick={() => setShowTabContentsForMobileDevices(false)}
                  className='absolute -top-1 left-3 text-xs'
                  title={t('back')}
                  size={BTN_FB_SIZE.SMALL}
                />
              ) : null}
              {
                getSettingPageTabContent(mode, index, customRoutine, platform)
                  .body
              }
            </TabPanel>
          ))}
      </Tabs>
      {isShowRoutineModal && <MorningAdvancedModal />}
      {isShowMoreOptionsOpened && position !== -1 && type && <MoreOptions />}
      {isUnlockHabitPackModalShown && <UnlockHabitPackModal />}
    </>
  );
};

export default TabBody;
