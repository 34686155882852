import ModalOverlay from 'components/shared/ModalOverlay';
import { t } from 'i18next';
import { useState } from 'react';
import { cancelSubscription } from 'services/subscription';
import {
  EMPTY_STRING,
  MINIMUM_CHARACTERS_CANCEL_REASON,
  POST_HOG_EVENT
} from 'constants/general';
import InfoCircle from 'assets/icons/InfoCircle';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Spinner from 'components/common/Spinner';
import { increment, isTeamOwnerOrAdmin } from 'utils/support';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { updateShowCancelReasonModal } from 'store/reducer/user/slice';
import { cancelReasonModalSelector } from 'store/reducer/user/selectors';
import { captureEvents } from 'utils/events';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

const CancelReasonModal = () => {
  const dispatch = useAppDispatch();
  const {
    isCancelingSubscription,
    entitlements,
    activeEntitlements,
    userEmail
  } = useAppSelector(cancelReasonModalSelector);
  const [reason, setReason] = useState(EMPTY_STRING);
  const shouldAllowCancelSubscription =
    increment(reason.length) > MINIMUM_CHARACTERS_CANCEL_REASON;
  const entitlement_id = isTeamOwnerOrAdmin(activeEntitlements ?? [])
    ? entitlements?.['team'].product_identifier
    : entitlements?.['personal'].product_identifier;

  return (
    <ModalOverlay styles='bg-gray-400/30'>
      <div
        className={`relative left-1.5 top-[99%] -translate-y-full w-[95%] sm:w-3/4 lg:w-1/2 h-fit flex flex-col items-center rounded-md p-10 ${isDarkModeActivated(useAppSelector(themeSelector)) ? 'bg-gray-700' : 'bg-focusBear'} overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 animate-bottomUpFast`}
      >
        <div className='w-full h-fit flex flex-col relative'>
          <div className='relative'>
            <textarea
              readOnly={isCancelingSubscription}
              value={reason}
              onChange={({ target: { value } }) => setReason(value)}
              className='w-full h-28 max-h-28 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 text-xs sm:text-sm p-2 sm:p-4 resize-none outline-none rounded-md shadow-md'
            ></textarea>
            <small
              className={`absolute bottom-2 right-2 text-xs z-50 ${!shouldAllowCancelSubscription && 'text-rose-500'}`}
            >
              {`${reason.length} / ${MINIMUM_CHARACTERS_CANCEL_REASON}`}
            </small>
          </div>

          <small className='flex gap-1 text-xs self-start pt-1 text-left'>
            <InfoCircle styles='min-w-[1rem] w-4 h-auto self-start' />
            {t('reason_should_be_minimum_characters', {
              reasonLength: MINIMUM_CHARACTERS_CANCEL_REASON
            })}
          </small>
        </div>
        <div className='w-full flex items-center justify-center gap-5 sm:gap-10 pt-6'>
          {isCancelingSubscription ? (
            <Spinner
              minHeight='w-fit h-full'
              loaderStyles='w-4 md:w-6 h-4 md:h-6'
            />
          ) : (
            <ButtonFocusBear
              disabled={!shouldAllowCancelSubscription}
              title={t('continue')}
              onClick={() => {
                captureEvents(
                  POST_HOG_EVENT.CANCEL_SUBSCRIPTION_CONTINUE_BUTTON_CLICKED,
                  userEmail
                );
                entitlement_id &&
                  cancelSubscription({
                    cancel_subscription_reason: reason,
                    entitlement_id
                  });
              }}
              size={BTN_FB_SIZE.SMALL}
              disableDarkMode
            />
          )}
          <ButtonFocusBear
            disabled={isCancelingSubscription}
            title={t('back')}
            onClick={() => dispatch(updateShowCancelReasonModal(false))}
            variant={BTN_FB_VARIANT.SECONDARY}
            size={BTN_FB_SIZE.SMALL}
            disableDarkMode
          />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default CancelReasonModal;
