import { StripePlanType } from 'interfaces';
import {
  LOCAL_STORAGE,
  POST_HOG_EVENT,
  SUBSCRIPTION_PLAN
} from 'constants/general';
import CheckMark from 'assets/icons/CheckMark';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getSubscriptionPlanInfo,
  saveMarkerIndicatingUserOpenedStripe,
  updateLocalStorage
} from 'utils/support';
import { getStripeCheckoutURL } from 'services/subscription';
import { updateError } from 'store/reducer/setting/slice';
import { updateShowTeamNameModal } from 'store/reducer/user/slice';
import { updateTeamOwnerAction } from 'store/reducer/team/slice';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import { isRouteEmbedded } from 'utils/validation';
import { useState } from 'react';
import { t } from 'i18next';
import { captureEvents } from 'utils/events';
import { subscriptionPlanSelector } from 'store/reducer/user/selectors';

const Plan = ({ plan }: { plan: StripePlanType }) => {
  const [isPlanClicked, setIsPlanClicked] = useState(false);
  const dispatch = useAppDispatch();
  const { title, description, features, price, price_id } =
    getSubscriptionPlanInfo(plan.id!);
  const userEmail = useAppSelector(subscriptionPlanSelector);

  const navigateToStripeCheckout = () => {
    try {
      setIsPlanClicked(true);
      isRouteEmbedded &&
        updateLocalStorage(
          LOCAL_STORAGE.REDIRECT_TO_MANAGE_SUBSCRIPTION_VIA_WEBVIEW,
          window.location.pathname
        );
      const isTeamPlan = plan.id === SUBSCRIPTION_PLAN.TEAM;
      captureEvents(
        isTeamPlan
          ? POST_HOG_EVENT.TEAM_SUBSCRIPTION_GET_STARTED_BUTTON_CLICKED
          : POST_HOG_EVENT.PERSONAL_SUBSCRIPTION_GET_STARTED_BUTTON_CLICKED,
        userEmail
      );

      if (isTeamPlan) {
        dispatch(updateShowTeamNameModal(true));
        dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.CREATE_NAME));
      } else {
        saveMarkerIndicatingUserOpenedStripe();
        getStripeCheckoutURL(price_id);
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        updateError({ message: t('errors.couldnt_fetch_stripe_checkout') })
      );
    }
  };

  return (
    <div className='w-full flex flex-col items-center rounded-xl shadow-md gap-6 sm:gap-8 md:gap-10 bg-whiteLace p-10 animate-fadeIn'>
      <h4 className='italic'>{title}</h4>
      <p className='text-sm md:text-base'>{description}</p>
      <h1 className='text-3xl sm:text-5xl md:text-6xl'>
        ${price.amount}
        <span className='text-gray-500 text-xs sm:text-base md:text-lg'>
          {price.postfix}
        </span>
      </h1>
      <button
        disabled={isPlanClicked}
        onClick={navigateToStripeCheckout}
        className='w-fit h-fit px-4 py-2 rounded-t-xl rounded-bl-xl border-2 border-black text-base sm:text-lg md:text-xl hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white shadow-md disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:border-gray-500 disabled:animate-pulse'
      >
        {t('get_started')}
      </button>
      <div className='flex flex-col gap-3 self-start'>
        {features.map((feature) => (
          <p key={feature} className='flex gap-2 text-sm md:text-base'>
            <span className='pt-1'>
              <CheckMark />
            </span>
            {feature}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Plan;
