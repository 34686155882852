import { THEME_OPTION } from 'constants/enum';
import {
  ACTIVITY_TYPES,
  INDEX,
  NODE_ENV,
  PRIORITY_OPTION,
  QUERIES,
  STRIPE_CUSTOMER_ID_CHARACTER
} from 'constants/general';
import { NewTaskFormValues, ValidationErrorNewTask } from 'interfaces';
import { ActivityType } from 'interfaces/commonInterface';
import { SettingsType } from 'interfaces/settingInterface';
import { EMBEDDED_PAGE, ROUTES } from 'constants/routes';
import _ from 'lodash';

export const validateNewToDoTask = (values: NewTaskFormValues) => {
  const errors: ValidationErrorNewTask = {};

  if (!values.title) {
    errors.title = '*';
  }
  if (!values.due_date) {
    errors.due_date = '*';
  }
  if (!values.eisenhower_quadrant.value) {
    errors.eisenhower_quadrant = '*';
  }
  return errors;
};

export const validateInputDuration = (
  value: string,
  maxValue: number,
  type?: string
) => {
  if (parseFloat(value) < 0) {
    return { error: true, data: -1 };
  }

  if (type === ACTIVITY_TYPES.BREAKING) {
    return parseInt(value) > maxValue
      ? { error: true, data: -1 }
      : { error: false, data: Math.abs(parseInt(value)) };
  } else {
    return parseFloat(value) > maxValue
      ? { error: true, data: -1 }
      : {
          error: false,
          data: Math.abs(parseInt(value))
        };
  }
};

export const validateUrl = (value: string) =>
  /(http|https)?:\/\/([\w\d-]+\.)+\w{2,}(\/.+)?$/.test(value);

export const isRouteEmbedded = [
  ROUTES.WEBVIEW_CHAT,
  ROUTES.WEBVIEW_MOTIVATIONAL_SUMMARY,
  ROUTES.WEBVIEW_STATS,
  ROUTES.WEBVIEW_TODO_LIST,
  ROUTES.WEBVIEW_TOOLS_TODO_LIST,
  EMBEDDED_PAGE,
  ROUTES.WEBVIEW_MOBILE_MOTIVATIONAL_SUMMARY,
  ROUTES.WEBVIEW_COURSE,
  ROUTES.WEBVIEW_FOCUS_END,
  ROUTES.WEBVIEW_TO_DO_PLAYER,
  ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION
].includes(window.location.pathname);

export const isDarkModeActivated = (mode: THEME_OPTION) =>
  mode === THEME_OPTION.DARK;

export const isAppInProduction = () =>
  process.env.NODE_ENV === NODE_ENV.PRODUCTION;

export const isEmbeddedTodoList = (pathname: string) =>
  [ROUTES.WEBVIEW_TODO_LIST, ROUTES.WEBVIEW_TOOLS_TODO_LIST].includes(pathname);

export const isStripeCustomerId = (arg: string) =>
  /^cus_[a-zA-Z0-9]+/i.test(arg) && arg.length === STRIPE_CUSTOMER_ID_CHARACTER;

export const hasCompletionRequirement = (habit: ActivityType) =>
  Boolean(habit?.completion_requirements);

export const isValidImageURL = (url: string) => {
  if (typeof url !== 'string') {
    return false;
  }
  return !!url.match(/^http.*\.(jpeg|jpg|png)$/);
};

export const checkEmbeddedQuery = (queriesString: string) => {
  const queries = queriesString.split('&');
  if (queries.length) {
    const queriesArray = queries[INDEX.ZERO].replace('?', '').split('=');
    return (
      queriesArray?.[INDEX.ZERO] === QUERIES.EMBEDDED &&
      queriesArray?.[INDEX.ONE] === 'true'
    );
  }
  return false;
};

export const isCurrentPlayingActivityFromChoice = (
  activities: ActivityType[],
  currentPlayingActivity: ActivityType
) => {
  let result: {
    parent_activity_id: string | null;
    choice_id: string | null;
  } = { parent_activity_id: null, choice_id: null };
  // change so that all sequence types can have choices
  activities?.forEach((activity: ActivityType) => {
    const choice = activity?.choices?.find(
      (choice) => choice.id === currentPlayingActivity?.id
    );
    if (choice) {
      result = {
        parent_activity_id: activity.id,
        choice_id: choice.id
      };
    } else if (activity.id === currentPlayingActivity?.id) {
      result = {
        parent_activity_id: activity.id,
        choice_id: null
      };
    }
  });
  return result;
};

export const isActivityFilterByAll = (
  activity: ActivityType,
  searchWord: string,
  priority: string,
  daysOfWeek?: string[]
) => {
  const isSubStringFound =
    searchWord === ''
      ? true
      : String(activity?.name ?? '')
          .toLowerCase()
          .includes(searchWord.toLowerCase());
  const isPrioritized =
    priority === ''
      ? true
      : (activity?.priority ?? PRIORITY_OPTION.STANDARD) === priority;
  const selectedDays =
    activity.days_of_week?.filter((day) => daysOfWeek?.includes(day)) ?? [];
  const areSelectedDaysExists =
    daysOfWeek && daysOfWeek.length > 0 ? selectedDays.length > 0 : true;

  return isSubStringFound && isPrioritized && areSelectedDaysExists;
};

/**
 * @returns boolean by comparing the current user settings
 * with the previous/existing one(i.e the previous settings
 * might come from mac,win ... apps)
 */
export const compareSettings = (
  currentSettings: SettingsType,
  previousSettings: SettingsType
) => {
  let has_edited_settings = false;
  const currentSetting: any = { ...currentSettings }; // eslint-disable-line @typescript-eslint/no-explicit-any
  const previousSetting: any = { ...previousSettings }; // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key in previousSetting) {
    if (
      key === ACTIVITY_TYPES.MORNING ||
      key === ACTIVITY_TYPES.EVENING ||
      key === ACTIVITY_TYPES.BREAKING ||
      key === ACTIVITY_TYPES.STANDALONE
    ) {
      if (currentSetting[key].length !== previousSetting[key].length) {
        has_edited_settings = true;
        break;
      } else {
        for (const subKey in previousSetting[key]) {
          const previous_setting_child = _.omit(previousSetting[key][subKey], [
            'id',
            'activity_sequence_id'
          ]);
          const current_setting_child = _.omit(currentSetting[key][subKey], [
            'id',
            'activity_sequence_id'
          ]);
          if (!_.isEqual(previous_setting_child, current_setting_child)) {
            has_edited_settings = true;
            break;
          } else {
            has_edited_settings = false;
          }
        }
        if (has_edited_settings) {
          break; //@Description: if any of the child has been edited, then break the outer loop
        }
      }
    } else {
      if (!_.isEqual(previousSetting[key], currentSetting[key])) {
        has_edited_settings = true;
        break;
      }
    }
  }
  return has_edited_settings;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isYoutubeURL = (url: string) =>
  url.match(
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu(?:be)?\.com\/(?:v\/|embed\/|shorts\/|watch(?:\/|\?v=))|youtu\.be\/)((?:\w|-){11})(?:\S+)?$/
  );

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
