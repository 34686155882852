import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MAC_APP_DATA_LOCATION, PLATFORMS, ROUTINE } from 'constants/general';
import {
  changeCurrentSettings,
  updateAreSettingsSaving,
  updateIsSettingEditingFinished,
  updateIsUserSettingsModified,
  updateRoutineSuggestions,
  updateShowEditSuggestionsModal
} from 'store/reducer/setting/slice';
import { saveUserSettings } from 'store/reducer/setting/extra';
import { FLAG, Mode } from 'constants/enum';
import { upsertHabitPack } from 'store/reducer//habit-pack/extra';
import { upsertFocusModeTemplate } from 'store/reducer/focus-mode/extra';
import { filterOutCutoffTimeAndTutorial } from 'utils/support';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { compareSettings, isDarkModeActivated } from 'utils/validation';
import ValidationErrors from './ValidationErrors';
import { t } from 'i18next';
import { sendDataToPlatform } from 'utils/focusBear_apps';

const TabActions = () => {
  const dispatch = useAppDispatch();
  const {
    setting: {
      currentSettings,
      previousSettings,
      platform,
      mode,
      areSettingsValid: isDataValid,
      isUserSettingsModified,
      themeMode,
      flags,
      customRoutine
    },
    focusMode: {
      currentViewedFocusTemplate,
      initialCurrentViewedFocusTemplate
    },
    habitPack: { initialCurrentViewedPack }
  } = useAppSelector((state) => state);

  const isCutOffTimeValid =
    currentSettings.cutoff_time_for_non_high_priority_activities
      ? moment(
          currentSettings.cutoff_time_for_non_high_priority_activities,
          'HH:mm'
        ).isSameOrAfter(moment(currentSettings.shutdown_time, 'HH:mm'))
      : true;

  const areSettingsValid = [Mode.DEFAULT, Mode.FREESTYLE].includes(mode)
    ? Boolean(currentSettings.startup_time) &&
      Boolean(currentSettings.shutdown_time) &&
      Boolean(currentSettings.break_after_minutes) &&
      isCutOffTimeValid &&
      isDataValid
    : isDataValid;

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const isRoutineSuggestions = useMemo(
    () => mode === Mode.CUSTOM && customRoutine === ROUTINE.SUGGESTION,
    [mode, customRoutine]
  );

  const [morning_activities, break_activities] = useMemo(
    () =>
      filterOutCutoffTimeAndTutorial(
        currentSettings.morning_activities ?? [],
        currentSettings.break_activities ?? []
      ),
    [currentSettings]
  );

  useEffect(() => {
    const doSettingsModified = isUserSettingsEdited();
    dispatch(updateIsUserSettingsModified(doSettingsModified));
    flags.includes(FLAG.SETTINGS_UPDATED) &&
      sendDataToPlatform(
        platform,
        { areSettingsEdited: doSettingsModified, areSettingsValid },
        MAC_APP_DATA_LOCATION.USER_SETTINGS_UPDATED
      );
  }, [currentSettings, currentViewedFocusTemplate]);

  const handleFinish = () => {
    if (isRoutineSuggestions) {
      const activities = [
        ...(currentSettings.morning_activities ?? []),
        ...(currentSettings.evening_activities ?? [])
      ];
      dispatch(updateRoutineSuggestions(activities));
      dispatch(updateShowEditSuggestionsModal(false));
    } else {
      dispatch(updateIsSettingEditingFinished(true));
      if (window.location.href.includes('dashboard')) {
        if (mode === Mode.ROUTINE || mode === Mode.STANDALONE) {
          dispatch(upsertHabitPack(currentSettings));
        } else if (mode === Mode.FOCUS_MODE && currentViewedFocusTemplate) {
          dispatch(upsertFocusModeTemplate(currentViewedFocusTemplate));
        } else {
          dispatch(updateAreSettingsSaving(true));
          dispatch(
            saveUserSettings({
              ...currentSettings,
              has_edited_settings: compareSettings(
                currentSettings,
                previousSettings
              ),
              morning_activities,
              break_activities
            })
          );
        }
      } else {
        mode === Mode.FREESTYLE &&
          dispatch(
            changeCurrentSettings({
              ...currentSettings,
              morning_activities: [],
              evening_activities: []
            })
          );
      }
    }
  };

  const handleSave = () => {
    dispatch(updateIsSettingEditingFinished(true));
    [Mode.DEFAULT, Mode.FREESTYLE, Mode.CUSTOM].includes(mode) &&
      sendDataToPlatform(
        platform,
        { ...currentSettings, morning_activities, break_activities },
        MAC_APP_DATA_LOCATION.SUBMIT_USER_SETTINGS
      );
  };

  const isUserSettingsEdited = () => {
    if (
      platform === PLATFORMS.WEB &&
      (mode === Mode.ROUTINE || mode === Mode.STANDALONE)
    ) {
      return compareSettings(currentSettings, {
        ...currentSettings,
        ...initialCurrentViewedPack
      });
    } else if (
      platform === PLATFORMS.WEB &&
      mode === Mode.FOCUS_MODE &&
      initialCurrentViewedFocusTemplate
    ) {
      return compareSettings(
        currentViewedFocusTemplate ?? {},
        initialCurrentViewedFocusTemplate
      );
    } else {
      return compareSettings(currentSettings, previousSettings);
    }
  };

  const formatBackground = useMemo(() => {
    if (areSettingsValid) {
      return shouldActivateDarkMode
        ? 'border-focusBearText text-focusBearText hover:text-white hover:bg-orange-400'
        : 'border-blue-500 text-blue-500 hover:text-white hover:bg-blue-500';
    } else {
      return 'border-gray-500 text-gray-500 cursor-not-allowed';
    }
  }, [areSettingsValid, shouldActivateDarkMode]);

  const saveBtnTitle = useMemo(() => {
    if (customRoutine === ROUTINE.TIMING) {
      return t('save_block_schedule');
    } else if (isRoutineSuggestions || platform === PLATFORMS.WEB) {
      return t('finish');
    } else {
      return t('save_restart');
    }
  }, [customRoutine, isRoutineSuggestions, platform]);

  return isUserSettingsModified ? (
    <div className='w-full h-[5%] md:h-[7%] self-end flex items-center justify-end px-4 z-50 gap-5'>
      <ValidationErrors />
      <button
        disabled={!areSettingsValid}
        onClick={() => {
          isRoutineSuggestions || platform === PLATFORMS.WEB
            ? handleFinish()
            : handleSave();
        }}
        className={`w-max h-fit px-2 sm:px-3 lg:px-6 py-0.5 sm:py-1 lg:py-2 text-base rounded-lg md:rounded-xl border-2 ${formatBackground} font-bold `}
      >
        {saveBtnTitle}
      </button>
    </div>
  ) : null;
};

export default TabActions;
