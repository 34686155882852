import { createAsyncThunk } from '@reduxjs/toolkit';
import focusBearApi from 'services/axios-config';
import * as Sentry from '@sentry/react';
import {
  updateIsAssigningAdmin,
  updateIsInvitingUser,
  updateAreTeamsLoading,
  updateIsRemovingAdmin,
  updateIsRemovingMember,
  updateIsUpdatingTeamName,
  updateAreMembersLoading,
  updateShowTeamOwnerActionModal,
  updateSelectedTeam,
  updateTeams,
  updateIsAcceptingInvitation,
  updateIsUpdatingExpiryDate,
  updateSelectedMemberId,
  updateShowTeamWelcomeModal,
  updateIsInvitationAccepted
} from './slice';
import Endpoints from 'constants/endpoints';
import { EMPTY_STRING, HTTP_STATS_CODE } from 'constants/general';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { RootState } from 'store';
import { InviteMemberPayload, Team } from 'interfaces';

export const fetchAdminTeams = createAsyncThunk(
  'team/fetch_admin_teams',
  async (_, { dispatch }) => {
    try {
      dispatch(updateAreTeamsLoading(true));
      const { data } = await focusBearApi.get(
        `${Endpoints.TEAM_MANAGEMENT}/admin-teams`
      );
      return data ?? [];
    } catch (error) {
      dispatch(updateAreTeamsLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const fetchAllMembers = createAsyncThunk(
  'team/fetch_all_members',
  async (team_id: string, { dispatch }) => {
    try {
      dispatch(updateAreMembersLoading(true));
      const { data } = await focusBearApi.get(
        `${Endpoints.TEAM_MANAGEMENT}/all-members`,
        {
          params: {
            team_id
          }
        }
      );
      return data;
    } catch (error) {
      dispatch(updateAreMembersLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const inviteMember = createAsyncThunk(
  'team/invite_member',
  async (data: InviteMemberPayload, { dispatch }) => {
    try {
      dispatch(updateIsInvitingUser(true));
      const { status } = await focusBearApi.post(
        `${Endpoints.TEAM_MANAGEMENT}/invite-member`,
        data
      );
      dispatch(updateIsInvitingUser(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(updateShowTeamOwnerActionModal(false));
        toast.success(i18n.t('admin_team.invitation_sent_successfully'));
      } else {
        toast.error(i18n.t('admin_team.could_not_send_the_invitation'));
      }
    } catch (error) {
      dispatch(updateIsInvitingUser(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const removeMember = createAsyncThunk(
  'team/remove_member',
  async (data: { member_id: string; team_id: string }, { dispatch }) => {
    try {
      dispatch(updateIsRemovingMember(true));
      await focusBearApi.post(
        `${Endpoints.TEAM_MANAGEMENT}/remove-member`,
        data
      );
      dispatch(updateIsRemovingMember(false));
    } catch (error) {
      dispatch(updateIsRemovingMember(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const assignAdmin = createAsyncThunk(
  'team/assign_admin',
  async (data: { member_id: string; team_id: string }, { dispatch }) => {
    try {
      dispatch(updateIsAssigningAdmin(true));
      const { status } = await focusBearApi.post(
        `${Endpoints.TEAM_MANAGEMENT}/assign-admin`,
        data
      );
      dispatch(updateIsAssigningAdmin(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(fetchAllMembers(data.team_id));
      } else {
        toast.error(i18n.t('admin_team.could_not_assign_admin_role'));
      }
    } catch (error) {
      dispatch(updateIsAssigningAdmin(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const removeAdmin = createAsyncThunk(
  'team/remove_admin',
  async (data: { member_id: string; team_id: string }, { dispatch }) => {
    try {
      dispatch(updateIsRemovingAdmin(true));
      const { status } = await focusBearApi.post(
        `${Endpoints.TEAM_MANAGEMENT}/remove-admin`,
        data
      );
      dispatch(updateIsRemovingAdmin(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(fetchAllMembers(data.team_id));
      } else {
        toast.error(i18n.t('admin_team.could_not_remove_admin_role'));
      }
    } catch (error) {
      dispatch(updateIsRemovingAdmin(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const modifyTeamName = createAsyncThunk(
  'team/modify_team_name',
  async (data: { name: string; team_id: string }, { dispatch, getState }) => {
    try {
      dispatch(updateIsUpdatingTeamName(true));
      const { status } = await focusBearApi.put(
        `${Endpoints.TEAM_MANAGEMENT}/name`,
        data
      );
      dispatch(updateIsUpdatingTeamName(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        const { teams, selectedTeam } = (getState() as RootState).team;
        dispatch(
          updateTeams(
            teams.map((team: Team) =>
              team.id === data.team_id ? { ...team, name: data.name } : team
            )
          )
        );
        selectedTeam &&
          dispatch(updateSelectedTeam({ ...selectedTeam, name: data.name }));
        dispatch(updateShowTeamOwnerActionModal(false));
      } else {
        toast.error(i18n.t('admin_team.could_not_update_team_name'));
      }
    } catch (error) {
      dispatch(updateIsUpdatingTeamName(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  'team/accept_invitation',
  async (token: string, { dispatch }) => {
    try {
      dispatch(updateIsAcceptingInvitation(true));
      const { status } = await focusBearApi.post(
        `${Endpoints.TEAM_MANAGEMENT}/accept-invitation`,
        { token }
      );
      dispatch(updateIsAcceptingInvitation(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(i18n.t('admin_team.invitation_accepted_successfully'));
        dispatch(updateIsInvitationAccepted(true));
        dispatch(updateShowTeamWelcomeModal(true));
      } else {
        toast.error(i18n.t('could_not_process_the_request'));
      }
    } catch (error) {
      dispatch(updateIsAcceptingInvitation(false));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateMemberExpiryDate = createAsyncThunk(
  'team/update_member_expiry_data',
  async (
    data: { member_id: string; team_id: string; expiry_date: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsUpdatingExpiryDate(true));
      const { status } = await focusBearApi.put(
        `${Endpoints.TEAM_MANAGEMENT}/member-expiry`,
        data
      );
      dispatch(updateIsUpdatingExpiryDate(false));
      dispatch(updateSelectedMemberId(EMPTY_STRING));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(fetchAllMembers(data.team_id));
      } else {
        toast.error(i18n.t('admin_team.could_not_remove_admin_role'));
      }
    } catch (error) {
      dispatch(updateIsUpdatingExpiryDate(false));
      dispatch(updateSelectedMemberId(EMPTY_STRING));
      toast.error(i18n.t('could_not_process_the_request'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
